import React from 'react'
import './CardPlaceHolder.css'

const CardPlaceHolder = () => {
    return (
        <div aria-hidden="true" className="card card-shadow product-card product-card-marketplace my-2 p-2 text-secondary">
            <div className="d-flex card-placeholder-image-padding">
                <div className="spinner-border" style={{ margin: "auto", height: "4rem", objectFit: "cover", width: "4rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>

            <div className="card-body">
                <h5 className="card-title placeholder-glow text-center">
                    <span className="placeholder col-6"></span>
                </h5>
                <p className="  placeholder-glow">
                    <span className="placeholder col-7"></span>
                    <span className="placeholder col-5"></span>
                </p>
            </div>
        </div>
    );
};

export default CardPlaceHolder