import React from 'react'
import { useNavigate } from "react-router-dom";
import RatingAndViews from '../shared/RatingAndViews';
import { limitarString } from '../../utils/limitarString';
import './CompanyCard.css'

// Import Material UI Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import HomeIcon from '@mui/icons-material/Home';
import MedicationIcon from '@mui/icons-material/Medication';

const CompanyCard = ({ company }) => {
    const navigate = useNavigate();

    return (
        <div className='card p-2 my-2 bg-white product-card shadow border product-card-marketplace' onClick={() => navigate("/perfil/" + company._id)} >
            <div className='card-body p-0'>
                <img className="company-card-image" src={company.image.url} alt="" />
                <div>
                    <h6 className='mb-0 mt-2' style={{ fontWeight: 'bold' }}>
                        {limitarString(company.fullname)}
                    </h6>
                    <>
                        <LocationOnIcon style={{ color: 'grey', marginLeft: '-5px' }} fontSize='small' />
                        <p className="d-inline" style={{ textTransform: 'capitalize' }}>
                            {limitarString(company.address ? company.address : "Sin ubicación")}
                        </p>
                    </>
                    <RatingAndViews ratingList={company.sellerRating} viewCount={company.viewCount} />
                </div>
            </div>
            <div className='bg-white m-auto p-0 text-center' style={{
                width: '28px', height: '28px', overflow: 'hidden', borderRadius: '50%', position: 'absolute', right: '15px', top: '15px',
                boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"
            }}>
                {company.companyCategory === 'tienda' && (
                    <LocalGroceryStoreIcon fontSize='small' />
                )}
                {company.companyCategory === 'veterinaria' && (
                    <MedicationIcon fontSize='small' />
                )}
                {company.companyCategory === 'guarderia' && (
                    <HomeIcon fontSize='small' />
                )}
                {company.companyCategory === 'peluqueria' && (
                    <ContentCutIcon fontSize='small' />
                )}
            </div>
        </div>
    )
}

export default CompanyCard