import React, { useEffect, useState } from "react";
import CardPlaceHolderGroup from "../components/shared/CardPlaceHolderGroup";
import API from "../utils/API";
import ModalDetailService from "../components/marketplace/ModalDetailProduct";
import NewServiceForm from "../components/profile/NewServiceForm";
import ServiceCard from "../components/services/ServiceCard"
import { useSelector } from "react-redux";

// Material UI imports
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';

const Services = () => {
    const authenticated = useSelector((state) => state.user.authenticated);

    const [services, setServices] = useState([]);
    const [cardPlaceholder, setCardPlaceholder] = useState(true);
    const [show, setShow] = useState(false);
    const [serviceSelected, setServiceSelected] = useState();
    const [serviceOwner, setServiceOwner] = useState();
    const [categoryFilter, setCategoryFilter] = useState("cualquiera");
    const [searchBarFilter, setSearchBarFilter] = useState("")
    const [newServiceModal, setNewServiceModal] = useState(false);

    useEffect(() => {
        setNewServiceModal(false);

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        API.get(`/api/services`, { headers }).then((res) => {
            setServices(res.data);
            setCardPlaceholder(false);
        });

        if (serviceSelected) {
            API.get(`/api/user/${serviceSelected.owner._id}`, { headers }).then((res) => {
                setServiceOwner(res.data.user);
            });
        }
    }, [serviceSelected]);

    const handleShow = (service) => {
        setServiceSelected(service);
        setShow(true);
    };

    const handleChangeCategoryFilter = (event) => {
        setCategoryFilter(event.target.value);
    };

    const handleChangeSearchBarFilter = (event) => {
        setSearchBarFilter(event.target.value);
    };

    function applyCategoryFilter(value) {
        if (value["category"] === undefined) {
            return false;
        }

        // Verify the category selected includes the item
        if (categoryFilter.toLowerCase() === "cualquiera" || categoryFilter.toLowerCase() === value["category"].toLowerCase()) {
            return true;
        }

        return false;
    }

    function applySearchBarFilter(value) {
        // Verify the item match with the searchbar characters
        if (searchBarFilter === "" || (value["title"].toLowerCase().includes(searchBarFilter.toLowerCase()) && searchBarFilter !== "")) {
            return true;
        }

        return false;
    }

    const servicesAfterFilter = services.filter(value => applyCategoryFilter(value) && applySearchBarFilter(value));

    const allowedCategories = [
        "guarderia",
        "aseo y estetica",
        "adiestramiento y educación",
        "salud y cuidado veterinario",
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-evenly mt-2" >
                <FormControl className="my-3 marketplace-filter-field">
                    <TextField id="demo-simple-select" value={searchBarFilter} label="Buscador" onChange={event => handleChangeSearchBarFilter(event)}></TextField>
                </FormControl>
                <FormControl className="marketplace-filter-field">
                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                    <Select id="demo-simple-select" value={categoryFilter} label="Categoria" onChange={handleChangeCategoryFilter}>
                        <MenuItem value="cualquiera">Cualquiera</MenuItem>
                        {/* Mapear las categorías permitidas para generar las opciones del select */}
                        {allowedCategories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {capitalizeFirstLetter(category)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {
                    authenticated && (
                        <div className="d-flex flex-wrap align-items-center" style={{ justifyContent: 'center' }}>
                            <button className="btn btn-primary mb-2 mt-4 w-75" onClick={() => setNewServiceModal(true)}>Añadir Nuevo Servicio</button>
                        </div>
                    )
                }
            </div>
            <section className="d-flex flex-wrap" style={{ justifyContent: "space-between", alignItems: "center" }}>
                {!cardPlaceholder &&
                    servicesAfterFilter.map((service) => (
                        <ServiceCard service={service} key={service._id} onClick={() => handleShow(service)} />
                    ))}
                {cardPlaceholder && <CardPlaceHolderGroup />}
                {servicesAfterFilter.length < 1 && (
                    <h2 className="text-center mt-5 mx-auto">Actualmente no hay servicios publicados</h2>
                )}
            </section>
            {serviceOwner && <ModalDetailService show={show} setShow={setShow} productSelected={serviceSelected} />}
            <NewServiceForm show={newServiceModal} onHide={() => setNewServiceModal(false)} />
        </div>
    );
};

export default Services;
