import React, { useEffect, useState } from "react";
import CardPlaceHolderGroup from "../components/shared/CardPlaceHolderGroup";
import API from "../utils/API";
import ModalDetailProduct from "../components/marketplace/ModalDetailProduct";
import NewProductForm from "../components/profile/NewProductForm";
import ProductCard from "../components/marketplace/ProductCard"
import { useSelector } from "react-redux";

// Material UI imports
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

const Marketplace = () => {
    const authenticated = useSelector((state) => state.user.authenticated);

    const [products, setProducts] = useState([]);
    const [cardPlaceholder, setCardPlaceholder] = useState(true);
    const [show, setShow] = useState(false);
    const [productSelected, setProductSelected] = useState();
    const [productOwner, setProductOwner] = useState();
    const [newProductModal, setNewProductModal] = useState(false);

    const [categoryFilter, setCategoryFilter] = useState("cualquiera");
    const [searchBarFilter, setSearchBarFilter] = useState("");
    const [minPriceFilter, setMinPriceFilter] = useState(null);
    const [maxPriceFilter, setMaxPriceFilter] = useState(null);

    useEffect(() => {
        setNewProductModal(false);

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        API.get(`/api/products`, { headers }).then((res) => {
            setProducts(res.data);
            setCardPlaceholder(false);
        });

        if (productSelected) {
            API.get(`/api/user/${productSelected.owner._id}`, { headers }).then((res) => {
                setProductOwner(res.data.user);
            });
        }
    }, [productSelected]);

    const handleShow = (product) => {
        setProductSelected(product);
        setShow(true);
    };

    const handleChangeCategoryFilter = (event) => {
        setCategoryFilter(event.target.value);
    };

    const handleChangeMinPriceFilter = (event) => {
        if (event.target.value.length >= 1) {
            setMinPriceFilter(event.target.value);
        } else {
            setMinPriceFilter(null)
        }
    };

    const handleChangeMaxPriceFilter = (event) => {
        if (event.target.value.length >= 1) {
            setMaxPriceFilter(event.target.value);
        } else {
            setMaxPriceFilter(null);
        }
    };

    const handleChangeSearchBarFilter = (event) => {
        setSearchBarFilter(event.target.value);
    };

    function applyCategoryFilter(value) {
        if (value["category"] === undefined) {
            return false;
        }

        // Verify the category selected includes the item
        if (categoryFilter.toLowerCase() === "cualquiera" || categoryFilter.toLowerCase() === value["category"].toLowerCase()) {
            return true;
        }
        return false;
    }

    function applySearchBarFilter(value) {
        // Verify the item match with the searchbar characters
        if (searchBarFilter === "" || (value["title"].toLowerCase().includes(searchBarFilter.toLowerCase()) && searchBarFilter !== "")) {
            return true;
        }
        return false;
    }

    function applyPriceFilter(value) {
        const itemPrice = value["price"];
        if (minPriceFilter !== null && itemPrice < minPriceFilter) {
            return false;
        }
        if (maxPriceFilter !== null && itemPrice > maxPriceFilter) {
            return false;
        }
        return true;
    }

    const productsAfterFilter = products.filter(value => applyCategoryFilter(value) && applySearchBarFilter(value) && applyPriceFilter(value));

    const allowedCategories = [
        "alimentos",
        "juguetes",
        "accesorios",
        "higiene y cuidado",
        "ropa",
        "salud y bienestar",
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-between mt-2 mb-3">
                <FormControl className="mt-3 marketplace-filter-field" style={{ width: '33%' }}>
                    <TextField id="demo-simple-select" value={searchBarFilter} label="Buscador" onChange={event => handleChangeSearchBarFilter(event)}></TextField>
                </FormControl>
                <FormControl className="mt-3 marketplace-filter-field" style={{ width: '33%' }}>
                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                    <Select id="demo-simple-select" value={categoryFilter} label="Categoria" onChange={handleChangeCategoryFilter}>
                        <MenuItem value="cualquiera">Cualquiera</MenuItem>
                        {/* Mapear las categorías permitidas para generar las opciones del select */}
                        {allowedCategories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {capitalizeFirstLetter(category)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="d-flex justify-content-between marketplace-filter-field-prices mt-3" style={{ width: '33%' }}>
                    <FormControl style={{ width: '49%' }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Precio Minimo</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio Minimo"
                            onChange={event => handleChangeMinPriceFilter(event)}
                        />
                    </FormControl>
                    <FormControl style={{ width: '49%' }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Precio Maximo</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio Maximo"
                            onChange={event => handleChangeMaxPriceFilter(event)}
                        />
                    </FormControl>
                </div>
                {
                    authenticated && (
                        <div className="d-flex flex-wrap align-items-center" style={{ justifyContent: 'center' }}>
                            <button className="btn btn-primary mb-2 mt-4 w-75" onClick={() => setNewProductModal(true)}>Añadir Nuevo Producto</button>
                        </div>
                    )
                }
            </div>
            <section className="d-flex flex-wrap" style={{ justifyContent: "space-between", alignItems: "center" }}>
                {!cardPlaceholder &&
                    productsAfterFilter.map((product) => (
                        <ProductCard product={product} key={product._id} onClick={() => handleShow(product)} />
                    ))}
                {cardPlaceholder && <CardPlaceHolderGroup />}
            </section>
            {productOwner && <ModalDetailProduct show={show} setShow={setShow} productSelected={productSelected} />}
            <NewProductForm show={newProductModal} onHide={() => setNewProductModal(false)} />
        </div>
    );
};

export default Marketplace;
