import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import RatingAndViews from "../shared/RatingAndViews";
import '../../pages/Profile.css'
import { toast } from "react-hot-toast";
import API from "../../utils/API";
import { useState } from "react";
import WhatsAppButton from "../shared/WhatsAppButton";
import EditProduct from "./EditProduct";

// Import from material ui
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CommentBox from "../shared/CommentBox";

const ModalDetailProduct = ({ show, setShow, productSelected, userIsOwner = false, isService = false }) => {
    const [showEditForm, setShowEditForm] = useState(false)
    const navigate = useNavigate();

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const config = {
        headers: {
            "x-user-id": userId,
            "x-access-token": token,
        },
    };

    const deleteProduct = async (event) => {
        event.preventDefault();
        API.delete(`/api/product/${productSelected._id}/`, config)
            .then((res) => {
                toast.success("Has eliminado el producto");
                setShow(false);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message);
            });
    };

    const deleteService = async (event) => {
        event.preventDefault();
        API.delete(`/api/service/${productSelected._id}/`, config)
            .then((res) => {
                toast.success("Has eliminado el servicio");
                setShow(false);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message);
            });
    };

    const editProduct = () => {
        setShowEditForm(true);
    }

    const backFromEditProduct = () => {
        setShowEditForm(false);
    }

    return (
        <>
            {productSelected && (
                <Modal show={show} onHide={() => setShow(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{showEditForm ? 'Editar Producto' : 'Detalles de Producto'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            showEditForm ? (
                                <div className="d-flex justify-content-center flex-wrap">
                                    <div className="w-100">
                                        <EditProduct product={productSelected} backFromEditProduct={backFromEditProduct} />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="d-flex p-3">
                                        <img onClick={() => navigate("/perfil/" + productSelected.owner._id)} src={productSelected.owner.image.url} className="bg-info rounded-circle d-flex align-items-center justify-content-center shadow-1-strong" style={{ width: "35px", height: "35px", objectFit: "cover", cursor: 'pointer' }} alt="owner" />
                                        <p className="lead mb-0 ms-2"><b>{productSelected.owner.fullname}</b></p>
                                    </div>
                                    <img src={productSelected.image.url} className="card-img-top" alt="producto" style={{ maxHeight: "50vh", objectFit: "contain" }} />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <p className="small">
                                                <a href="#!" className="text-muted" style={{ textTransform: "capitalize" }}>
                                                    {productSelected.category}
                                                </a>
                                            </p>
                                        </div>

                                        <div className="d-flex justify-content-between mb-3">
                                            <h5 className="mb-0">{productSelected.title}</h5>
                                            <h5 className="text-dark mb-0">${productSelected.price}</h5>
                                        </div>
                                        <RatingAndViews ratingList={productSelected.valuation} viewCount={productSelected.viewCount} />
                                        {
                                            !userIsOwner && (
                                                <div className="d-flex justify-content-center mt-3">
                                                    <button className="btn-petgout"><WhatsAppButton text={"Comprar"} typeOfMessage={1} phoneNumber={productSelected.owner.cellphone} name={productSelected.owner.fullname} /></button>
                                                </div>
                                            )
                                        }
                                        {
                                            userIsOwner && (
                                                <div className="mt-3 d-flex" style={{ justifyContent: 'space-evenly' }}>
                                                    <div
                                                        onClick={isService ? deleteService : deleteProduct}
                                                        className="btn btn-danger d-flex align-items-center"
                                                    >
                                                        <DeleteIcon className='custom-icon' />
                                                        <h5 className="mb-0 ms-2">Eliminar</h5>
                                                    </div>
                                                    <div
                                                        className="btn btn-secondary d-flex align-items-center"
                                                        onClick={editProduct}
                                                    >
                                                        <EditIcon className='custom-icon' />
                                                        <h5 className="mb-0 ms-1">Editar</h5>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <CommentBox targetId={productSelected._id} />
                                </>
                            )
                        }
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default ModalDetailProduct;
