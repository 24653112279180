import React, { useState } from 'react'
import './PublicProfileLoader.css'
import { useEffect } from 'react'

const PublicProfileLoader = ({ showLoader }) => {
    const [showVisible, SetShowVisible] = useState(true)
    useEffect(() => {
        if (!showLoader) {
            setTimeout(() => {
                SetShowVisible(false)
            }, 500);
        }
    }, [showLoader])


    return (
        <div className='d-flex align-items-center justify-content-center public-profile-loader-container'
            style={{
                opacity: showLoader ? 1 : 0,
                visibility: !showVisible && 'hidden'
            }}>
            <div>
                <div class="dog">
                    <div class="ears"></div>
                    <div class="body">
                        <div class="eyes"></div>
                        <div class="beard">
                            <div class="mouth">
                                <div class="tongue"></div>
                            </div>
                        </div>
                        <div class="stomach">
                        </div>
                        <div class="legs">
                            <div class="left"></div>
                            <div class="right"></div>
                        </div>
                    </div>
                    <div class="tail">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicProfileLoader