import React, { useEffect, useState } from "react";
import CardPlaceHolderGroup from "../components/shared/CardPlaceHolderGroup";
import API from "../utils/API";

// Imports from material ui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import WalkerCard from "../components/walkers/WalkerCard";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

const Walkers = () => {
    const [walkers, setWalkers] = useState([]);
    const [cardPlaceholder, setCardPlaceholder] = useState(true);
    const [address, setAddress] = React.useState("Cualquiera");
    const [locations, setLocations] = useState([])
    const [searchBarFilter, setSearchBarFilter] = useState("")
    const [minPriceFilter, setMinPriceFilter] = useState(null);
    const [maxPriceFilter, setMaxPriceFilter] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        API.get(`/api/walkers`, { headers }).then((res) => {
            setWalkers(res.data);
            setCardPlaceholder(false);
        });

        API.get(`/api/location`, { headers }).then((res) => {
            setLocations(res.data);
        });

    }, []);

    function applyAddressFilter(value) {
        if (address.toLowerCase() === "cualquiera") {
            return true;
        }
        if (value["address"] === undefined) {
            return false;
        }
        if (value["address"].toLowerCase() === address.toLowerCase()) {
            return true;
        }
        return false;
    }

    function applySearchBarFilter(value) {
        // Verify the item match with the searchbar characters
        if (searchBarFilter === "" || (value["fullname"].toLowerCase().includes(searchBarFilter.toLowerCase()) && searchBarFilter !== "")) {
            return true;
        }
        return false;
    }

    function applyPriceFilter(value) {
        const itemPrice = value["walkerPrice"];
        if (minPriceFilter !== null && itemPrice < minPriceFilter) {
            return false;
        }
        if (maxPriceFilter !== null && itemPrice > maxPriceFilter) {
            return false;
        }
        return true;
    }

    const walkersAfterFilter = walkers.filter(value => applyAddressFilter(value) && applySearchBarFilter(value) && applyPriceFilter(value));

    const handleChangeSearchBarFilter = (event) => {
        setSearchBarFilter(event.target.value);
    };

    const handleChangeLocation = (event) => {
        setAddress(event.target.value);
    };

    const handleChangeMinPriceFilter = (event) => {
        if (event.target.value.length >= 1) {
            setMinPriceFilter(event.target.value);
        } else {
            setMinPriceFilter(null)
        }
    };

    const handleChangeMaxPriceFilter = (event) => {
        if (event.target.value.length >= 1) {
            setMaxPriceFilter(event.target.value);
        } else {
            setMaxPriceFilter(null);
        }
    };


    return (
        <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-between mt-2 mb-3">
                <FormControl className="mt-3 walkers-filter-field" style={{ width: '33%' }}>
                    <TextField id="demo-simple-select" value={searchBarFilter} label="Buscador" onChange={event => handleChangeSearchBarFilter(event)}></TextField>
                </FormControl>
                <FormControl className="mt-3 walkers-filter-field" style={{ width: '33%' }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Ubicación</InputLabel>
                    <Select labelId="demo-simple-select-autowidth-label" id="demo-simple-select-autowidth" value={address} onChange={handleChangeLocation} autoWidth label="Ubicación">
                        <MenuItem value="Cualquiera">Cualquiera</MenuItem>
                        {locations.map((location) => (
                            <MenuItem key={location._id} value={location.name}>{location.name.charAt(0).toUpperCase() + location.name.slice(1)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="d-flex justify-content-between marketplace-filter-field-prices mt-3" style={{ width: '33%' }}>
                    <FormControl style={{ width: '49%' }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Precio Minimo</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio Minimo"
                            onChange={event => handleChangeMinPriceFilter(event)}
                        />
                    </FormControl>
                    <FormControl style={{ width: '49%' }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Precio Maximo</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio Maximo"
                            onChange={event => handleChangeMaxPriceFilter(event)}
                        />
                    </FormControl>
                </div>
            </div>
            <section className="d-flex flex-wrap" style={{ justifyContent: "space-between", alignItems: "center" }}>
                {!cardPlaceholder &&
                    walkersAfterFilter.map((walker) => (
                        <WalkerCard key={walker._id} walker={walker} />
                    ))}
                {cardPlaceholder && <CardPlaceHolderGroup />}
            </section>
        </div>
    );
};

export default Walkers;
