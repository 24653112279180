import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './JoinUsBanner.css';

const JoinUsBanner = () => {
    return (
        <div className="join-us-banner">
            <div className="join-us-content">
                <h2>¡Unete hoy mismo y se parte de nuestra comunidad!</h2>
                <p>Puedes ofrecer tus servicios como paseador o publicar productos y servicios para mascotas.</p>
                <Link to="/registro">
                    <button className="btn-petgout">Registrarme</button>
                </Link>
            </div>
        </div>
    );
};

export default JoinUsBanner;
