import React from 'react'
import { IconButton, InputAdornment, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

const AddressField = ({ address, setUpdateUserAddressModal }) => {

    return (
        <div className='profile-data-field'>
            <TextField
                id="outlined-read-only-input"
                label="Ubicación"
                value={address !== 'undefined' && address !== null ? address || '' : 'Desconocida'}
                className="w-100  my-2"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <>
                            <InputAdornment position="end">
                                <IconButton onClick={() => setUpdateUserAddressModal(true)}>
                                    <EditIcon
                                        color="primary"
                                        fontSize="large"
                                        style={{
                                            background: '#eefcff',
                                            borderRadius: '50%',
                                            padding: '.3rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        </>
                    )
                }}
            />
        </div>
    );
}

export default AddressField