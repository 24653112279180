import React, { useEffect, useState } from 'react'
import ProductCard from '../marketplace/ProductCard'
import API from "../../utils/API";
import ModalDetailProduct from "../marketplace/ModalDetailProduct";
import NewProductForm from './NewProductForm';

const UserProducts = ({ userInfo }) => {
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(false);
    const [productSelected, setProductSelected] = useState();
    const [newProductModal, setNewProductModal] = useState(false);

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const headers = {
        "x-access-token": token,
        "x-user-id": userId,
    };

    useEffect(() => {
        setNewProductModal(false);
        if (userInfo && userInfo._id) getProducts();
    }, [userInfo])

    const getProducts = async () => {
        try {
            const response = await API.get(`/api/products/${userInfo._id}`, { headers });
            setProducts(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleShow = (product) => {
        setProductSelected(product);
        setShow(true);
    };

    return (
        <>
            <div className="d-flex flex-wrap align-items-center" style={{ justifyContent: 'center' }}>
                <button className="btn btn-primary mb-4" onClick={() => setNewProductModal(true)}>Crear Producto</button>
            </div>
            <div className='d-flex flex-wrap justify-content-between'>
                {products.length > 0 ? (
                    products.map((product) => (
                        <ProductCard key={product._id} product={product} isForPublicProfile={true} onClick={() => handleShow(product)} />
                    ))
                ) : (
                    <div className='w-100 h-100 shadow border bg-white p-3 d-flex align-items-center justify-content-center' style={{ borderRadius: '20px' }}>
                        <h5 className='mb-0'>Esta cuenta aún no cuenta con productos publicados</h5>
                    </div>
                )}
            </div>
            <ModalDetailProduct show={show} setShow={setShow} productSelected={productSelected} userIsOwner={true} />
            <NewProductForm show={newProductModal} onHide={() => setNewProductModal(false)} />
        </>
    )
}

export default UserProducts;
