import React from "react";
import { useSelector } from "react-redux";
import SwiperCards from "../components/home/SwiperCards";
import 'moment/locale/es';
import Slider from "../components/home/Slider";
import JoinUsBanner from "../components/home/JoinUsBanner";
import './Home.css'
import FoundationsBanner from "../components/home/FoundationsBanner";

const Home = () => {
    const authenticated = useSelector((state) => state.user.authenticated);
    console.log(authenticated)
    const profilePhoto = useSelector((state) => state.user.userImage);
    console.log(profilePhoto);

    return (
        <div style={{ position: 'relative' }}>
            <Slider />
            {
                !authenticated && (
                    <JoinUsBanner />
                )
            }
            <section className="m-auto pt-1 pb-3" style={{ width: '92%', overflow: 'hidden' }}>
                <div className="px-2 py-2 my-3 bg-white">
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}>Productos</h4>
                    <SwiperCards option={'products'} />
                </div>
                <div className="px-2 py-2 my-3 bg-white">
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}>Paseadores</h4>
                    <SwiperCards option={'walkers'} />
                </div>
                {/* <div className="px-2 py-2 my-3 bg-white">
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}>Servicios</h4>
                    <SwiperCards option={'services'} />
                </div> */}
                <div className="px-2 py-2 my-3 bg-white">
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}>Negocios</h4>
                    <SwiperCards option={'companies'} />
                </div>
            </section>
            <FoundationsBanner />
        </div>
    );
};

export default Home;
