import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RatingAndViews = ({ ratingList, viewCount = 0 }) => {

  const [rating, setRating] = useState(0);
  const [ratingQuantity, setratingQuantity] = useState(0);

  useEffect(() => {
    const ratingValue = calculateTotalRating(ratingList)
    setRating(ratingValue)
    setratingQuantity(ratingList.length)
  }, [])


  const renderStars = () => {
    const stars = [];
    const filledStars = Math.floor(rating); // Estrellas llenas (doradas)
    const hasHalfStar = rating % 1 !== 0; // Verificar si hay una mitad de estrella
    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0); // Estrellas vacías (grises)

    // Renderizar estrellas llenas
    for (let i = 0; i < filledStars; i++) {
      stars.push(<i key={i} className="fas fa-star text-warning" />);
    }

    // Renderizar media estrella (si corresponde)
    if (hasHalfStar) {
      stars.push(<i key="half-star" className="fas fa-star-half-alt text-warning" />);
    }

    // Renderizar estrellas vacías
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<i key={filledStars + i} className="fa fa-star-o text-warning" />);
    }

    return stars;
  };

  const calculateTotalRating = (ratingList) => {
    if (ratingList.length === 0) {
      return 0
    }
    let total = 0;
    for (let i = 0; i < ratingList.length; i++) {
      total += ratingList[i]["stars"];
    }
    let average = total / ratingList.length;
    return average
  }

  return (
    <div className='d-flex flex-wrap align-items-center'>
      <span className='me-1'>{renderStars()}</span>
      <p className='d-flex mb-0' style={{ fontSize: '13px', color: 'gray', justifyContent: 'space-between' }}>
        <span className='d-inline'>{ratingQuantity} Reseñas</span>
        <div className='ms-4 d-flex' style={{ alignItems: 'center' }}>
          <VisibilityIcon fontSize='small' />
          <span className='mb-0 ms-1'>{viewCount}</span>
        </div>
      </p>
    </div>
  );
};

export default RatingAndViews;
