import React from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';
import RatingAndViews from '../shared/RatingAndViews';
import './HomeCardPlaceholder.css';

const HomeCardPlaceholder = () => {
  return (
    <Card className="card-placeholder">
      <Skeleton variant="rectangular" sx={{ height: 140 }} />
      <CardContent>
        <Skeleton variant="text" sx={{ marginBottom: 2 }} />
        <Skeleton variant="text" sx={{ marginBottom: 2 }} />
        <RatingAndViews ratingList={[0, 0, 0, 0, 0]} />
        <Skeleton variant="text" />
      </CardContent>
    </Card>
  );
};

export default HomeCardPlaceholder;
