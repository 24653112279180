import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
    'Solicitud Aceptada',
    // 'Retirar Mascota',
    // 'Paseo En Progreso',
    // 'Entregar Mascota',
    'Paseo Completado',
];

const WalkRequestStepper = ({step}) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>
                            <p>{label}</p>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default WalkRequestStepper