import React from 'react'

const TermsAndConditions = () => {
    return (
        <div className='my-4' style={{width: '85%', maxWidth: '600px', margin: 'auto', textAlign: 'justify'}}>
            <h1>Términos y condiciones</h1>
            <p>Bienvenido a los términos y condiciones de nuestra aplicación de cuidado de mascotas ("la aplicación"). Estos términos y condiciones rigen el uso de la aplicación y cualquier servicio asociado que proporcionemos. Al utilizar la aplicación, aceptas estar sujeto a estos términos y condiciones. Si no estás de acuerdo con estos términos y condiciones, no debes utilizar la aplicación.</p>

            <h2>Uso de la aplicación</h2>
            <p>(a) Debes tener 18 años o más para utilizar la aplicación. Si eres menor de edad, debes obtener el consentimiento de tus padres o tutores legales antes de utilizar la aplicación.</p>
            <p>(b) Al utilizar la aplicación, aceptas cumplir con todas las leyes y regulaciones aplicables. No debes utilizar la aplicación para fines ilegales o inapropiados.</p>

            <h2>Registro y cuentas de usuario</h2>
            <p>(a) Para utilizar la aplicación, debes registrarte y crear una cuenta de usuario. Al registrarte, debes proporcionar información precisa y completa.</p>
            <p>(b) Eres responsable de mantener la confidencialidad de tu nombre de usuario y contraseña. No debes compartir tu nombre de usuario o contraseña con terceros.</p>

            <h2>Propiedad intelectual</h2>
            <p>(a) Todos los derechos de propiedad intelectual relacionados con la aplicación y sus contenidos son propiedad nuestra o de nuestros licenciantes. No debes utilizar la aplicación o sus contenidos para fines comerciales sin nuestro consentimiento previo por escrito.</p>
            <p>(b) Si consideras que tu propiedad intelectual ha sido violada por la aplicación, debes notificarnos de inmediato.</p>

            <h2>Limitación de responsabilidad</h2>
            <p>(a) No asumimos ninguna responsabilidad por cualquier daño o pérdida que puedas sufrir como resultado del uso de la aplicación o de cualquier servicio asociado que proporcionemos.</p>
            <p>(b) No garantizamos la disponibilidad ininterrumpida de la aplicación o de cualquier servicio asociado que proporcionemos.</p>

            <h2>Modificaciones de los términos y condiciones</h2>
            <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en la aplicación. Si continúas utilizando la aplicación después de la publicación de las modificaciones, se considerará que has aceptado los términos y condiciones modificados.</p>

            <h2>Terminación</h2>
            <p>Nos reservamos el derecho de terminar tu acceso a la aplicación o a cualquier servicio asociado que proporcionemos en cualquier momento, y por cualquier motivo. Si terminamos tu acceso, no tendrás derecho a ningún tipo de indemnización.</p>

            <h2>Ley aplicable</h2>
            <p>Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes del país donde operamos.</p>
        </div>
    )
}

export default TermsAndConditions