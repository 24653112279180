import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import 'moment/locale/es';

export default function NotificationContain({ notification }) {

    const notificationTypeHandler = (notificationType) => {
        if (notificationType === 'por-confirmar') return 'por confirmar';
        return notificationType
    }

    const formatToFullDate = (UTCDate) => {
        moment.locale('es');
        const date = moment(UTCDate).fromNow();
        return date
    };

    const takeTimeFromDateType = (UTCDate) => {
        moment.locale('es');
        const hour = moment(UTCDate).format('h:mm a');
        const date = moment(UTCDate).format('D [de] MMMM');
        return `${date} a las ${hour}`
    };

    return (
        <ListItem alignItems="flex-start" style={{ cursor: 'pointer' }}>
            <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={notification.petOwner.image.url} />
            </ListItemAvatar>
            <ListItemText
                primary={`Solicitud de paseo ${notificationTypeHandler(notification.status)}`}
                secondary={
                    <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {`De ${notification.petOwner.fullname}`}
                        </Typography>
                        {` - Programada para el ${takeTimeFromDateType(notification.schedule)}`}
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}
