import React from 'react'
import MapboxComponent from '../mapbox/MapboxComponent';
import './InfoSection.css';

const InfoSection = ({ userInfo }) => {

    return (
        <>
            <div className='p-3 bg-white shadow border' style={{ borderRadius: '20px' }}>
                <p className='mb-3 info-section-header'>
                    Descripción
                </p>
                {
                    userInfo.description ? (
                        <p className='mb-0' style={{ color: 'grey', fontSize: '14px', lineHeight: '1rem' }}>
                            {userInfo.description}
                        </p>
                    ) : (
                        <p className='mb-0' style={{ color: 'grey', fontSize: '14px', lineHeight: '1rem' }}>
                            El usuario aun no cuenta con descripción.
                        </p>
                    )
                }
            </div>
            <div className='p-3 mt-3 bg-white  shadow border' style={{ borderRadius: '20px' }}>
                <p className='mb-0 d-flex align-items-center' style={{ color: 'grey', fontSize: '14px', lineHeight: '1rem' }}>
                    <MapboxComponent height={"400px"} width={"100%"} coordinates={[-74.08175, 4.60971]} />
                </p>
            </div>
        </>
    )
}

export default InfoSection