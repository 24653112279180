import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { setAuthenticated, setUserImage } from "./features/user/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";

import Layout from "./components/layout/Layout";

// Import pages
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Walkers from "./pages/Walkers";
import Marketplace from "./pages/Marketplace";
import SignInSide from "./pages/SignInSide";
import SignUpSide from "./pages/SignUpSide";
import Companies from "./pages/Companies";
import PublicProfile from "./pages/PublicProfile";
import TermsAndConditions from "./pages/TermsAndConditions";
import Support from "./pages/Support";
import Services from "./pages/Services";
import Adoptions from "./pages/Adoptions";
import Foundations from "./pages/Foundations";

function App() {
    const authenticated = useSelector((state) => state.user.authenticated);
    const dispatch = useDispatch();
    dispatch(setAuthenticated());
    dispatch(setUserImage());

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Navigate to="/home" replace />} />
                <Route path="terminos-y-condiciones" element={<Layout />}>
                    <Route index element={<TermsAndConditions />} />
                </Route>
                <Route path="preguntas-frecuentes" element={<Layout />}>
                    <Route index element={<Support />} />
                </Route>
                <Route path="/perfil/:id" element={<Layout />}>
                    <Route index element={<PublicProfile />} />
                </Route>
                <Route path="/negocios" element={<Layout />}>
                    <Route index element={<Companies />} />
                </Route>
                <Route path="/home" element={<Layout />}>
                    <Route index element={<Home />} />
                </Route>
                <Route path="/tienda" element={<Layout />}>
                    <Route index element={<Marketplace />} />
                </Route>
                <Route path="/servicios" element={<Layout />}>
                    <Route index element={<Services />} />
                </Route>
                <Route path="/paseadores" element={<Layout />}>
                    <Route index element={<Walkers />} />
                </Route>
                <Route path="/adopta" element={<Layout />}>
                    <Route index element={<Adoptions />} />
                </Route>
                <Route path="/fundaciones" element={<Layout />}>
                    <Route index element={<Foundations />} />
                </Route>
                {authenticated ? (
                    <>
                        <Route path="/perfil" element={<Layout />}>
                            <Route index element={<Profile />} />
                        </Route>
                    </>
                ) : (
                    <>
                        <Route path="/login" element={<SignInSide />} />
                        <Route path="/registro" element={<SignUpSide />} />
                    </>
                )}
            </Routes>
            <Toaster position="bottom-right" />
        </BrowserRouter>
    );
}

export default App;
