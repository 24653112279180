import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeCardPlaceholder from "./HomeCardPlaceHolder";

const SwiperCardPlaceHolderGroup = () => {
    return (
        <>
            <Swiper
                slidesPerView={2}
                spaceBetween={10}
                breakpoints={{
                    640: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                    },
                }}
                modules={[Pagination]}
                className="py-3 px-1"
            >
                <SwiperSlide>
                    <HomeCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCardPlaceholder />
                </SwiperSlide>
            </Swiper>
        </>
    );
};

export default SwiperCardPlaceHolderGroup;
