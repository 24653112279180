import React, { useEffect, useState } from 'react'
import { IconButton, InputAdornment, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import toast from "react-hot-toast";
import API from "../../utils/API";

const WalkerPriceField = ({ price, setUser }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableValue, setEditableValue] = useState(price);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        setUserId(localStorage.getItem("userId"));
    }, []);

    const handleSave = () => {
        if (editableValue.length > 0) {
            updatePrice()
            return
        }

        setIsEditing(false)
    }

    const handleEdit = () => {
        setIsEditing(true);
        setEditableValue(price)
    }

    const updatePrice = async () => {
        try {
            const token = localStorage.getItem("token");
            const formData = new FormData();

            formData.append("walkerPrice", editableValue);

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "x-user-id": userId,
                    "x-access-token": token,
                },
            };

            API.put(`/api/user/price/${userId}`, formData, config)
                .then((res) => {
                    setUser()
                    toast.success("Actualizaste tu celular");
                    setIsEditing(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.response.data.message);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='profile-data-field'>
            {isEditing ? (
                <TextField
                    id="outlined-read-only-input"
                    label="Precio por hora"
                    value={editableValue}
                    onChange={(e) => setEditableValue(e.target.value)}
                    className="w-100  my-2"
                    InputProps={{
                        endAdornment: (
                            <>
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSave}>
                                        <SaveIcon
                                            color="primary"
                                            fontSize="large"
                                            style={{
                                                background: '#eefcff',
                                                borderRadius: '50%',
                                                padding: '.3rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setIsEditing(false)}>
                                        <CloseIcon
                                            color="primary"
                                            fontSize="large"
                                            style={{
                                                background: '#ffeef8',
                                                borderRadius: '50%',
                                                padding: '.3rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            </>
                        )
                    }}

                />
            ) : (
                <TextField
                    id="outlined-read-only-input"
                    label="Precio por hora"
                    value={price !== 'undefined' && price !== null ? price || '' : 'Desconocida'}
                    className="w-100  my-2"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <>
                                <InputAdornment position="end">
                                    <IconButton onClick={handleEdit}>
                                        <EditIcon
                                            color="primary"
                                            fontSize="large"
                                            style={{
                                                background: '#eefcff',
                                                borderRadius: '50%',
                                                padding: '.3rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            </>
                        )
                    }}
                />
            )}
        </div >
    );
}


export default WalkerPriceField