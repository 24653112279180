import React, { useEffect, useState } from 'react'
import ProductCard from '../marketplace/ProductCard'
import API from "../../utils/API";
import ModalDetailProduct from "../marketplace/ModalDetailProduct";

const ProductsSection = ({ userInfo }) => {
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(false);
    const [productSelected, setProductSelected] = useState();

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        API.get(`/api/products/${userInfo._id}`, { headers }).then((res) => {
            setProducts(res.data);
        });
    }, [])

    const handleShow = (product) => {
        setProductSelected(product);
        setShow(true);
    };

    return (
        <div className='d-flex flex-wrap justify-content-between' style={{ border: '20px' }}>
            {products.length > 0 ? (
                products.map((product) => (
                    <ProductCard key={product._id} product={product} isForPublicProfile={true} onClick={() => handleShow(product)} />
                ))
            ) : (
                <div className='w-100 h-100 bg-white p-3 d-flex align-items-center justify-content-center shadow border' style={{ borderRadius: '20px' }}>
                    <p className='mb-0 info-section-header'>Esta cuenta aun no cuenta con productos publicados</p>
                </div>
            )}
            <ModalDetailProduct show={show} setShow={setShow} productSelected={productSelected} productOwner={userInfo} />
        </div>
    )
}

export default ProductsSection