import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserImage, setAuthenticated } from "../features/user/userSlice";
import API from "../utils/API";
import toast from "react-hot-toast";

// Material UI imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © Petgout "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const theme = createTheme();

export default function SignUpSide() {
    const dispatch = useDispatch();
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)
    const [actionButtonAvailable, setActionButtonAvailable] = useState(true)
    const [locations, setLocations] = useState([]);
    const [fullname, setFullname] = useState("");
    const [walkerPrice, setWalkerPrice] = useState()
    const [role, setRole] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [companyCategory, setCompanyCategory] = useState("")
    const [phone, setPhone] = useState('');
    const [phoneNumberValid, setPhoneNumberValid] = useState(false);
    const [isApiCalled, setApiCalled] = useState(false);
    const [renderUploadImageComponent, setRenderUploadImageComponent] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        // Check if the API has already been called
        if (!isApiCalled) {
            API.get(`/api/location`, { headers }).then((res) => {
                setLocations(res.data);
            });

            // Mark the API as called, so it won't be executed again
            setApiCalled(true);
        }

        // Check if all required fields are filled
        if (fullname && role && address && email && password && confirmPassword && termsAndConditionsAccepted && phone) {
            setActionButtonAvailable(false);
        } else {
            setActionButtonAvailable(true);
        }
    }, [fullname, role, address, email, password, confirmPassword, termsAndConditionsAccepted, phone, isApiCalled]);

    const handleSubmit = (event) => {
        try {
            event.preventDefault();

            if (!phoneNumberValid) throw new Error('El número de celular no es valido');
            if (password !== confirmPassword) throw new Error('Las contraseñas no coinciden');
            if (profilePhoto.length === 0) throw new Error('La foto de perfil es obligatoria');

            const body = {
                email,
                password,
                fullname,
                role,
                address,
                cellphone: phone
            };

            if (role === "empresa") {
                if (companyCategory === "") throw new Error("Debes especificar la categoria de negocio.")
                body["companyCategory"] = companyCategory
            }

            if (role === "walker") {
                if (walkerPrice === "") throw new Error("Debes especificar el precio por hora del paseador.")
                body["walkerPrice"] = walkerPrice
            }

            API.post("/api/signup", body)
                .then((res) => {
                    const { email, fullname, image } = res.data.user;
                    let userId = res.data.user._id;
                    let token = res.data.token;

                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("userId", userId);
                    localStorage.setItem("email", email);
                    localStorage.setItem("fullname", fullname);
                    localStorage.setItem("profilePhoto", image["url"]);
                    localStorage.setItem("role", role);
                    localStorage.setItem("address", address);

                    dispatch(setUserImage());
                    dispatch(setAuthenticated());
                    navigate("/perfil");

                    toast.success("Te has registrado correctamente");

                    updatePhoto(userId, token)
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                    console.log(error);
                });
        } catch (error) {
            toast.error(error.message);
            console.log(error);
        }
    };

    const handleBack = () => {
        setRenderUploadImageComponent(false);
        setProfilePhoto("");
    }

    const updatePhoto = async (userId, token) => {
        try {
            const formData = new FormData();
            formData.append("image", profilePhoto);

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "x-user-id": userId,
                    "x-access-token": token,
                },
            };

            API.put(`/api/user/${userId}`, formData, config)
                .then((res) => {
                    localStorage.setItem("profilePhoto", res.data);
                    dispatch(setUserImage());
                    toast.success("Añadiste una foto de perfil");
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.response.data.message);
                });
        } catch (error) {
            toast.error(error.message);
            console.log(error);
        }
    };

    const resetValues = () => {
        setFullname("")
        setWalkerPrice("")
        setRole("")
        setAddress("")
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        setCompanyCategory("")
        setPhone("")
        setPhoneNumberValid(false)
    }

    const handleChangeRole = (event) => {
        resetValues();
        setRole(event.target.value);
    }

    const handleWalkerPriceChange = (event) => {
        const inputValue = event.target.value;
        const numberValue = Number(inputValue);

        if (!isNaN(numberValue) && numberValue <= 90000) {
            setWalkerPrice(numberValue);
        }
    }

    // Logic to validate and send phone number
    const handlePhoneChange = (event) => {
        const phoneNumber = event.target.value;

        const formattedPhone = formatPhoneNumber(phoneNumber);
        setPhone(formattedPhone);

        const isValidColombianNumber = validateColombianPhoneNumber(formattedPhone);
        setPhoneNumberValid(isValidColombianNumber);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3');
        return formattedNumber;
    };

    const validateColombianPhoneNumber = (phoneNumber) => {
        const colombianRegex = /^(0)?[123456789]\d{9}$/;
        return colombianRegex.test(phoneNumber);
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: "url(https://images.pexels.com/photos/92380/pexels-photo-92380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            ¡Bienvenido a Petgout!
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            {
                                renderUploadImageComponent ? (
                                    <UploadImageComponent
                                        handleBack={handleBack}
                                        setProfilePhoto={setProfilePhoto}
                                        handleSubmit={handleSubmit}
                                    />
                                ) : (
                                    <RegisterUserDataForm
                                        role={role}
                                        walkerPrice={walkerPrice}
                                        handleWalkerPriceChange={handleWalkerPriceChange}
                                        handleChangeRole={handleChangeRole}
                                        companyCategory={companyCategory}
                                        fullname={fullname}
                                        email={email}
                                        phone={phone}
                                        handlePhoneChange={handlePhoneChange}
                                        locations={locations}
                                        password={password}
                                        setCompanyCategory={setCompanyCategory}
                                        setEmail={setEmail}
                                        setFullname={setFullname}
                                        setAddress={setAddress}
                                        confirmPassword={confirmPassword}
                                        termsAndConditionsAccepted={termsAndConditionsAccepted}
                                        navigate={navigate}
                                        actionButtonAvailable={actionButtonAvailable}
                                        address={address}
                                        setConfirmPassword={setConfirmPassword}
                                        setTermsAndConditionsAccepted={setTermsAndConditionsAccepted}
                                        setRenderUploadImageComponent={setRenderUploadImageComponent}
                                        setPassword={setPassword}
                                    />
                                )
                            }
                            <div className="text-center">
                                <Link to="/login" variant="body2">
                                    <span className="text-black">¿Ya te encuentras registrado?</span> Iniciar Sesión
                                </Link>
                            </div>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
}

const UploadImageComponent = ({ handleBack, setProfilePhoto, handleSubmit }) => {
    const [preview, setPreview] = useState(null);

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        setProfilePhoto(imageFile);
        setPreview(URL.createObjectURL(imageFile));
    };

    return (
        <div className="text-center d-flex flex-wrap mb-5 my-5">
            <Typography className="w-100" variant="h6">Elije una foto de perfil</Typography>
            <input
                className="mx-auto border btn"
                style={{ borderRadius: '20px' }}
                type="file"
                onChange={handleImageChange}
                accept="image/*"
            />
            <div className="w-100 my-4">
                <img style={{
                    borderRadius: '50%',
                    width: '200px',
                    height: '200px',
                    border: '4px solid white'
                }}
                    className="shadow"
                    src={preview ? preview : './profile.png'}
                    alt="Preview" />
            </div>
            <div className="w-100 d-flex justify-content-evenly mt-4">
                <Button className="btn-secondary" variant="contained" onClick={handleBack}>Retroceder</Button>
                <Button className="btn-primary" variant="contained" onClick={handleSubmit}>Crear usuario</Button>
            </div>
        </div>
    )
}

const RegisterUserDataForm = ({
    role,
    walkerPrice,
    handleWalkerPriceChange,
    handleChangeRole,
    companyCategory,
    fullname,
    email,
    phone,
    handlePhoneChange,
    locations,
    password,
    setCompanyCategory,
    setEmail,
    setFullname,
    setAddress,
    confirmPassword,
    termsAndConditionsAccepted,
    navigate,
    actionButtonAvailable,
    address,
    setConfirmPassword,
    setTermsAndConditionsAccepted,
    setRenderUploadImageComponent,
    setPassword
}) => {
    return (
        <>
            <FormControl margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={role} label="Rol" onChange={handleChangeRole}>
                    <MenuItem value="walker">Paseador</MenuItem>
                    <MenuItem value="cliente">Cliente</MenuItem>
                    <MenuItem value="empresa">Negocio</MenuItem>
                </Select>
            </FormControl>
            {
                role === "empresa" && (
                    <FormControl margin="normal" fullWidth>
                        <InputLabel id="demo-simple-select-label">Categoria de Negocio</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={companyCategory} label="Categoria de Negocio" onChange={(e) => setCompanyCategory(e.target.value)}>
                            <MenuItem value="tienda">Tienda</MenuItem>
                            <MenuItem value="veterinaria">Veterinaria</MenuItem>
                            <MenuItem value="peluqueria">Peluqueria</MenuItem>
                            <MenuItem value="guarderia">Guarderia</MenuItem>
                        </Select>
                    </FormControl>
                )
            }
            {
                role === "walker" && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="walker-price"
                        label="Precio por hora de paseo"
                        name="walker-price"
                        value={walkerPrice}
                        onChange={handleWalkerPriceChange}
                    />
                )
            }
            <TextField margin="normal" value={fullname} required fullWidth id="fullname" label="Nombre" name="fullname" autoComplete="fullname" onChange={(event) => setFullname(event.target.value)} />
            <TextField margin="normal" value={email} required fullWidth id="email" label="Correo Electronico" name="email" autoComplete="email" onChange={(event) => setEmail(event.target.value)} />
            <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Número celular"
                name="phone"
                autoComplete="tel"
                value={phone}
                onChange={handlePhoneChange}
            />
            <FormControl margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-label">Ubicación</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={address} label="Ubicación" onChange={(e) => setAddress(e.target.value)}>
                    {locations.map((location) => (
                        <MenuItem key={location._id} value={location.name}>{location.name.charAt(0).toUpperCase() + location.name.slice(1)}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField margin="normal" value={password} required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" onChange={(event) => setPassword(event.target.value)} />
            <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirmar contraseña"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
            />
            <FormGroup className="d-inline">
                <Checkbox
                    checked={termsAndConditionsAccepted}
                    onChange={event => setTermsAndConditionsAccepted(event.target.checked)}
                />
                <p className="d-inline">Acepto <span style={{ color: 'blue', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate("/terminos")}>terminos y condiciones</span></p>
            </FormGroup>
            {/* </><Button disabled={actionButtonAvailable} type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}> */}
            <Button disabled={actionButtonAvailable} fullWidth variant="contained" sx={{ mt: 2, mb: 2 }} onClick={() => setRenderUploadImageComponent(true)}>
                Siguiente
            </Button>
        </>
    )
}
