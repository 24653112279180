import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './FoundationsBanner.css';

const FoundationsBanner = () => {
    return (
        <div className="foundations-banner d-flex flex-wrap justify-content-evenly align-items-center">
            <div className='foundations-image-container'>
                <img className='img-fluid foundations-banner-image' src="./images/foundations-banner-image.png" alt="" />
            </div>
            <div className="foundations-content">
                <h2>¡Únete a nuestra causa y sé parte de la diferencia!</h2>
                <p>Apoya a los perritos rescatados de las calles de Colombia a través de donaciones o apadrinamiento. Descubre servicios de adopción y mucho más para tu amada mascota.</p>
                <Link to="/fundaciones">
                    <button className="btn-petgout">Ver Fundaciones</button>
                </Link>
            </div>
        </div>
    );
};

export default FoundationsBanner;
