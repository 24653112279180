import React from 'react'
import './ProductCard.css'
import RatingAndViews from '../shared/RatingAndViews'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { limitarString } from '../../utils/limitarString';

const ProductCard = ({ product, isForPublicProfile, onClick }) => {

    return (
        <div className={`card p-2 my-2 bg-white product-card border shadow ${isForPublicProfile ? '' : 'product-card-marketplace'}`} onClick={onClick} >
            <div className='card-body p-0'
                style={{
                    textAlign: 'left',
                    overflow: 'hidden'
                }}>
                <img
                    className={`mb-2 ${isForPublicProfile ? 'product-image-for-public-profile' : ''}`}
                    src={product.image.url}
                    alt={product.image.url} />
                <div>
                    <h6 className='mb-0' style={{fontWeight: 'bold'}}>
                        {limitarString(product.title)}
                    </h6>
                    {
                        !isForPublicProfile && (
                            <>
                                <LocationOnIcon style={{ color: 'grey', marginLeft: '-5px' }} />
                                <p className="d-inline" style={{ textTransform: 'capitalize' }}>
                                    {limitarString(product.owner.address ? product.owner.address : "Sin ubicación")}
                                </p>
                            </>)
                    }
                    <RatingAndViews ratingList={product.valuation} viewCount={product.viewCount} />
                    <p className='text-primary mb-0'><b>${product.price.toLocaleString('en-US').replace(',', '.')}</b></p>
                </div>
            </div>
        </div>
    )
}

export default ProductCard