import React, { useEffect, useState } from 'react'
import { IconButton, InputAdornment, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import toast from "react-hot-toast";
import API from "../../utils/API";

const CellPhoneField = ({ cellphone, setUser }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableValue, setEditableValue] = useState(cellphone);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        setUserId(localStorage.getItem("userId"));
    }, []);

    const validateColombianPhoneNumber = (phoneNumber) => {
        const colombianRegex = /^(0)?[123456789]\d{9}$/;
        return colombianRegex.test(phoneNumber);
    };

    const handleSave = () => {
        const cellphoneIsValid = validateColombianPhoneNumber(editableValue);

        if (!cellphoneIsValid) {
            // setIsEditing(false)
            toast.error("El número de celular es invalido");
            return
        }

        if (editableValue === cellphone) {
            // setIsEditing(false)
            toast.error("El número es igual al celular actual");
            return
        }

        if (cellphoneIsValid) {
            updateCellphone()
            return
        }

        setIsEditing(false)
    }

    const handleEdit = () => {
        setIsEditing(true);
        setEditableValue(cellphone)
    }

    const updateCellphone = async () => {
        try {
            const token = localStorage.getItem("token");
            const formData = new FormData();

            formData.append("cellphone", editableValue);

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "x-user-id": userId,
                    "x-access-token": token,
                },
            };

            API.put(`/api/user/cellphone/${userId}`, formData, config)
                .then((res) => {
                    setUser()
                    toast.success("Actualizaste tu celular");
                    setIsEditing(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.response.data.message);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='profile-data-field'>
            {isEditing ? (
                <TextField
                    id="outlined-read-only-input"
                    label="Celular"
                    value={editableValue}
                    onChange={(e) => setEditableValue(e.target.value)}
                    className="w-100  my-2"
                    InputProps={{
                        endAdornment: (
                            <>
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSave}>
                                        <SaveIcon
                                            color="primary"
                                            fontSize="large"
                                            style={{
                                                background: '#eefcff',
                                                borderRadius: '50%',
                                                padding: '.3rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setIsEditing(false)}>
                                        <CloseIcon
                                            color="primary"
                                            fontSize="large"
                                            style={{
                                                background: '#ffeef8',
                                                borderRadius: '50%',
                                                padding: '.3rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            </>
                        )
                    }}

                />
            ) : (
                <TextField
                    id="outlined-read-only-input"
                    label="Celular"
                    value={cellphone !== 'undefined' && cellphone !== null ? cellphone || '' : 'Desconocida'}
                    className="w-100  my-2"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <>
                                <InputAdornment position="end">
                                    <IconButton onClick={handleEdit}>
                                        <EditIcon
                                            color="primary"
                                            fontSize="large"
                                            style={{
                                                background: '#eefcff',
                                                borderRadius: '50%',
                                                padding: '.3rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            </>
                        )
                    }}
                />
            )}
        </div >
    );

}


export default CellPhoneField