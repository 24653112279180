import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authenticated: false,
    userImage: "",
    totalWalkRequests: 0,
    userInfo: {},
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserImage: (state) => {
            state.userImage = localStorage.getItem("profilePhoto");
        },
        setAuthenticated: (state) => {
            state.authenticated = localStorage.getItem("token") ? true : false;
        },
        countWalkRequests: (state, action) => {
            state.totalWalkRequests = action.payload
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        }
    },
});

// Action creators are generated for each case reducer function
export const { setUserImage, setAuthenticated, countWalkRequests, setUserInfo } = userSlice.actions;

export default userSlice.reducer;
