import React, { useEffect, useState } from "react";
import API from "../utils/API";
import './Profile.css'

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setUserInfo } from "../features/user/userSlice";

// Import Material UI components
import RatingOneStar from "../components/shared/RatingOneStar";

// Import React Boostrap
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import UpdateProfilePhotoModal from "../components/profile/UpdateProfilePhotoModal";
import UpdateUserAddressModal from "../components/profile/UpdateUserAddressModal";
import UserInfo from "../components/profile/UserInfo";
import WalkRequests from "../components/profile/WalkRequests";
import UserProducts from "../components/profile/UserProducts";
import UserServices from "../components/profile/UserServices";

const Profile = () => {
    // States to show modals
    const [updateProfileModal, setUpdateProfilePhotoModal] = useState(false);
    const [updateUserAddressModal, setUpdateUserAddressModal] = useState(false);

    // States to user info
    const profilePhoto = useSelector((state) => state.user.userImage);
    const userInfo = useSelector((state) => state.user.userInfo);
    const [address, setAddress] = useState("");

    // Aditional states
    const [show, setShow] = useState(false);
    const [petSelected, setPetSelected] = useState();
    const [cardPlaceholder, setCardPlaceholder] = useState(true);

    // Define Hooks
    const dispatch = useDispatch();

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const headers = {
        "x-access-token": token,
        "x-user-id": userId,
    };

    useEffect(() => {
        setUser();
        setUpdateProfilePhotoModal(false);
        setUpdateUserAddressModal(false);
    }, [userInfo.fullname, userInfo.email, address]);

    const setUser = () => {
        // Get user data by token
        API.get(`/api/my-user`, { headers })
            .then((res) => {
                dispatch(setUserInfo(res.data.user));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const calculateTotalRating = (ratingList) => {
        if (ratingList.length === 0) {
            return 0
        }
        let total = 0;
        for (let i = 0; i < ratingList.length; i++) {
            total += ratingList[i]["stars"];
        }
        let average = total / ratingList.length;
        return average
    }

    return (
        <div style={{ height: '100%' }}>
            <div className="card-body profile-img-camp">
                <div className="d-flex justify-content-center my-1 mx-auto" style={{ maxWidth: '42rem' }}>
                    <div className="text-center">
                        <div className="mb-4">
                            <img className="profile-page-img shadow mb-4 d-block m-auto" style={{ border: "4.5px solid white", objectFit: "cover" }} src={profilePhoto} alt="Ejemplo 1"></img>
                            {
                                userInfo.role === 'walker' && (
                                    <div className='text-center'>
                                        <h3>Valoración</h3>
                                        <RatingOneStar stars={calculateTotalRating(userInfo.walkerRating)} />
                                    </div>
                                )
                            }
                            <Button className="mt-2 mx-auto d-block " variant="primary" onClick={() => setUpdateProfilePhotoModal(true)}>
                                Actualizar Foto de Perfil
                            </Button>
                        </div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className='w-100 justify-content-center'>
                                <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
                                    <Nav.Link eventKey="first" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Info</Nav.Link>
                                </Nav.Item>
                                {
                                    userInfo.role !== 'empresa' && (
                                        <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
                                            <Nav.Link eventKey="second" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Paseos</Nav.Link>
                                        </Nav.Item>
                                    )
                                }
                                <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
                                    <Nav.Link eventKey="third" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Productos</Nav.Link>
                                </Nav.Item>
                                {
                                    userInfo.role === 'empresa' && (
                                        <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
                                            <Nav.Link eventKey="fourth" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Servicios</Nav.Link>
                                        </Nav.Item>
                                    )
                                }
                            </Nav>
                            <Tab.Content className='my-4'>
                                <Tab.Pane eventKey="first">
                                    <UserInfo
                                        userInfo={userInfo}
                                        setUser={setUser}
                                        setUpdateUserAddressModal={setUpdateUserAddressModal}
                                    />
                                </Tab.Pane>
                                {
                                    userInfo.role !== 'empresa' && (
                                        <Tab.Pane eventKey="second">
                                            <WalkRequests
                                                setPetSelected={setPetSelected}
                                                cardPlaceholder={cardPlaceholder}
                                                setShow={setShow}
                                                userInfo={userInfo}
                                            />
                                        </Tab.Pane>
                                    )
                                }
                                <Tab.Pane eventKey="third">
                                    <UserProducts
                                        userInfo={userInfo}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <UserServices
                                        userInfo={userInfo}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    {/* <div className="d-flex flex-wrap justify-content-center mt-2 mb-4">
                        {
                            userInfo.role === 'cliente' && (
                                <Button className="mt-2 mx-1" variant="primary" onClick={() => setNewPetModal(true)}>
                                    Añadir Mascota
                                </Button>
                            )
                        }
                    </div> */}
                </div>
            </div>
            <UpdateProfilePhotoModal show={updateProfileModal} onHide={() => setUpdateProfilePhotoModal(false)} />
            <UpdateUserAddressModal setAddress={setAddress} show={updateUserAddressModal} onHide={() => setUpdateUserAddressModal(false)} />
        </div>
    );
};

export default Profile;
