import React from 'react'
import './WhatsAppButton.css';

function WhatsAppButton({ phoneNumber = 3045960486, name, text = false, typeOfMessage }) {

    function generateWhatsAppLink(phoneNumber) {
        const formattedPhoneNumber = '+57' + phoneNumber;
        let message = '';

        // ----------------------------------------------------------------
        // typeOfMessage isn't a required parameter
        // ----------------------------------------------------------------
        // 1 = Productos
        // 2 = Paseador
        // 3 = Servicios
        // Default = Perfil
        // ----------------------------------------------------------------

        switch (typeOfMessage) {
            case 1:
                message = 'Hola, ' + name + ', estoy interesado(a) en tu producto en Petgout';
                break;
            case 2:
                message = 'Hola, ' + name + ', estoy interesado(a) en tus servicios como paseador en Petgout';
                break;
            case 3:
                message = 'Hola, ' + name + ', estoy interesado(a) en tus servicios en Petgout';
                break;
            case 4:
                message = 'Hola, ' + name + ', estoy interesado(a) en adoptar la mascota que has publicado en Petgout';
                break;
            default:
                message = 'Hola, ' + name + ' he visto tu perfil en Petgout';
                break;
        }

        const encodedMessage = encodeURIComponent(message);
        return `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
    }

    const whatsappLink = generateWhatsAppLink(phoneNumber);

    const handleButtonClick = () => {
        window.open(whatsappLink);
    };

    return (
        <div onClick={handleButtonClick} style={{ cursor: 'pointer' }} className='d-flex align-items-center justify-content-center'>
            {
                text && <h6 className='d-inline mb-0 me-1'><b>{text}</b></h6>
            }
            <img className="whatsapp-button" width="30" height="30" src="https://img.icons8.com/color/96/whatsapp--v1.png" alt="whatsapp--v1" />
        </div>
    );
}


export default WhatsAppButton