import React, { useState } from "react";
import API from "../../utils/API";
import toast from "react-hot-toast";

const EditProduct = ({ product, backFromEditProduct }) => {
    const [productTitle, setProductTitle] = useState(product.title);
    const [productPrice, setProductPrice] = useState(product.price);
    const [productCategory, setProductCategory] = useState(product.category);
    const [uploadingProduct, setUploadingProduct] = useState(false);

    const allowedCategories = [
        "alimentos",
        "juguetes",
        "accesorios",
        "higiene y cuidado",
        "ropa",
        "salud y bienestar",
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const updateProduct = async (event) => {
        event.preventDefault();
        setUploadingProduct(true);

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const formData = new FormData();

        formData.append("title", productTitle);
        formData.append("category", productCategory);
        formData.append("price", productPrice);
        formData.append("description", "Esta es una descripción");

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-user-id": userId,
                "x-access-token": token,
            },
        };
        API.put(`/api/product/${product._id}`, formData, config)
            .then((res) => {
                toast.success("Has editado el producto");
                setUploadingProduct(false);
            })
            .catch((error) => {
                console.log(error);
                setUploadingProduct(false);
                toast.error(error.response.data.message);
            });
    };

    return (
        <form className="row g-3 justify-content-center">
            <div className="col-md-12">
                <label htmlFor="product-title" className="form-label">
                    Titulo
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="product-title"
                    value={productTitle}
                    onChange={(event) => {
                        setProductTitle(event.target.value);
                    }}
                    required
                />
            </div>
            <div className="col-md-6">
                <label htmlFor="product-price" className="form-label">
                    Precio
                </label>
                <div className="input-group has-validation">
                    <span className="input-group-text" id="inputGroupPrepend">
                        <i className="fa-solid fa-dollar-sign"></i>
                    </span>
                    <input
                        type="number"
                        className="form-control"
                        id="product-price"
                        aria-describedby="inputGroupPrepend"
                        value={productPrice}
                        onChange={
                            (event) => setProductPrice(event.target.value)
                        }
                        required
                    />
                </div>
            </div>
            <div className="col-md-6">
                <label htmlFor="product-category" className="form-label">
                    Categoría
                </label>
                <select
                    className="form-select" id="product-category"
                    value={productCategory}
                    onChange={
                        (event) => setProductCategory(event.target.value)
                    }
                    required>
                    <option value="">Selecciona una categoría</option>
                    {/* Mapear las categorías permitidas para generar las opciones del select */}
                    {allowedCategories.map((category) => (
                        <option key={category} value={category}>
                            {capitalizeFirstLetter(category)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="d-flex mt-4" style={{ justifyContent: 'space-evenly' }}>
                <button
                    className="btn btn-primary" onClick={backFromEditProduct} >
                    Regresar
                </button>
                {uploadingProduct ? (
                    <button className="btn btn-success" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="ms-2">Cargando...</span>
                    </button>
                ) : (
                    <button className={"btn btn-success"} onClick={updateProduct}>Editar producto</button>
                )}
            </div>
        </form>
    )
}

export default EditProduct