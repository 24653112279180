import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import SwiperCardPlaceHolderGroup from "./SwiperCardPlaceHolderGroup";
import HomeCard from "./HomeCard";
import ModalDetailProduct from "../marketplace/ModalDetailProduct";

const SwiperCards = ({ option }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [productSelected, setProductSelected] = useState();

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const headers = {
    "x-access-token": token,
    "x-user-id": userId,
  };

  useEffect(() => {
    fetchData();
  }, [option]);

  const handleShow = (product) => {
    setProductSelected(product);
    if (productSelected) {
      incrementProductView();
    }
    setShow(true);
  };

  const incrementProductView = () => {
    API.put(`/api/product/${productSelected._id}/increment-view`, { headers })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const fetchData = async () => {
    try {
      let endpoint = "";

      switch (option) {
        case "walkers":
          endpoint = "/api/walkers";
          break;
        case "products":
          endpoint = "/api/products";
          break;
        case "services":
          endpoint = "/api/services";
          break;
        case "companies":
          endpoint = "/api/companies";
          break;
        default:
          setError(true);
          return;
      }

      const response = await API.get(endpoint, { headers });
      setData(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    // Handle error state
    return <div>Failed to fetch data. Please try again later.</div>;
  }

  return (
    <>
      {loading ? (
        <SwiperCardPlaceHolderGroup />
      ) : (
        <Swiper
          slidesPerView={2} // Show 2 images on small screens
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 6, // Show 6 images on screens wider than 640px (large screens)
              spaceBetween: 10,
            },
          }}
          modules={[Pagination]}
          className="py-3 px-1"
          style={{overflow: 'visible'}}
        >
          {data.map((item) => {
            const shouldApplyClick = option === 'products' || option === 'services';

            return (
              <SwiperSlide
                onClick={shouldApplyClick ? () => handleShow(item) : null}
                className="home-slide"
                key={item._id}
                style={{ cursor: 'pointer' }}
              >
                <HomeCard data={item} dataType={option} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <ModalDetailProduct show={show} setShow={setShow} productSelected={productSelected} />
    </>
  );
};

export default SwiperCards;
