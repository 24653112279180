import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import API from "../utils/API";
import PublicProfileTab from '../components/public_profile/PublicProfileTab'

// Moment imports
import moment from 'moment';
import 'moment/locale/es';
import './PublicProfile.css'
import WhatsAppButton from '../components/shared/WhatsAppButton';
import PublicProfileLoader from '../components/loaders/PublicProfileLoader';

const PublicProfile = () => {
    let { id } = useParams();
    const [userInfo, setUserInfo] = useState()
    const [showLoader, setShowLoader] = useState(true)
    const [containerHeight, setContainerHeight] = useState(true)

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        API.get(`/api/user/${id}`, { headers })
            .then((res) => {
                setUserInfo(res.data.user);
            })
            .catch((err) => {
                console.log(err.response);
            });

        setTimeout(() => {
            setShowLoader(false)
        }, 2000);

        setTimeout(() => {
            setContainerHeight(false)
        }, 2500);
    }, []);

    const formatDate = (date) => {
        moment.locale('es');
        var momentDate = moment(date);
        return momentDate.fromNow();
    };

    return (
        <>
            <div style={{
                height: containerHeight ? '100vh' : '100%',
                minHeight: !containerHeight && '100vh',
                overflow: containerHeight && 'hidden'
            }}>
                <div className='d-flex flex-wrap justify-content-center mx-3'>
                    <div className='w-100 d-flex justify-content-center'>
                        <div className='card shadow border my-4 w-100'
                            style={{
                                borderRadius: '20px',
                                maxWidth: '600px'
                            }}>
                            {userInfo !== undefined ? (
                                <>
                                    <h3 className='card-header text-center'>
                                        {userInfo.role === 'walker' && 'Paseador'}
                                        {userInfo.role === 'empresa' && 'Negocio'}
                                        {userInfo.role !== 'walker' && userInfo.role !== 'empresa' && userInfo.role.charAt(0).toUpperCase() + userInfo.role.slice(1)}
                                    </h3>
                                    <img className="card-img-top" style={{ maxHeight: '400px', minHeight: '270px', objectFit: 'cover' }} src={userInfo.image.url} alt={userInfo.fullname}></img>
                                    <div className="card-body pe-2" style={{ color: 'gray' }}>
                                        <div>
                                            <h4 className='mb-3 mx-auto text-center' style={{ fontWeight: 'bold' }}>{userInfo.fullname}</h4>
                                            <div className='w-100'>
                                                {
                                                    userInfo.role === 'empresa' && (
                                                        <div className='mb-2'>
                                                            <p className='m-0 p-0 d-inline' style={{ fontSize: '14px', fontWeight: 'bold', }}>Tipo de negocio: </p>
                                                            <p className='m-0 p-0 d-inline' style={{ textTransform: 'capitalize' }}>{userInfo.companyCategory !== undefined ? userInfo.companyCategory : 'Desconocido'}</p>
                                                        </div>
                                                    )
                                                }
                                                <div className='mb-2'>
                                                    <p className='m-0 p-0 d-inline' style={{ fontSize: '14px', fontWeight: 'bold', }}>Celular: </p>
                                                    <p className='m-0 p-0 d-inline'>{userInfo.cellphone !== undefined ? userInfo.cellphone : 'Desconocido'}</p>
                                                </div>
                                                <div className='mb-2'>
                                                    <p className='m-0 p-0 d-inline' style={{ fontSize: '14px', fontWeight: 'bold' }}>Ubicación: </p>
                                                    <p className='m-0 p-0 d-inline' style={{ textTransform: 'capitalize' }}>{userInfo.address !== undefined ? userInfo.address : 'Desconocido'}</p>
                                                </div>
                                                <div className='mb-2'>
                                                    <p className='m-0 p-0 d-inline' style={{ fontSize: '14px', fontWeight: 'bold' }}>Miembro desde: </p>
                                                    <p className='m-0 p-0 d-inline' style={{ textTransform: 'capitalize' }}>{formatDate(userInfo.createdAt)}</p>
                                                </div>
                                            </div>
                                            <p className='mb-0 mt-3 d-flex align-items-center mx-auto' style={{ color: 'grey', fontSize: '14px', lineHeight: '1rem', width: 'fit-content' }}>
                                                {
                                                    userInfo.cellphone ? (
                                                        <button className='btn btn-primary'>
                                                            <WhatsAppButton text={"Contactar"} phoneNumber={userInfo.cellphone} name={userInfo.fullname} />
                                                        </button>
                                                    ) : (
                                                        <button className='btn btn-primary'>
                                                            <WhatsAppButton text={"Contactar"} name={userInfo.fullname} />
                                                        </button>
                                                    )
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                    {userInfo !== undefined ? <PublicProfileTab userInfo={userInfo} /> : <></>}
                </div>
            </div>
            <PublicProfileLoader showLoader={showLoader} />
        </>
    )
}

export default PublicProfile