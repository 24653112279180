import React from "react";
import CardPlaceHolder from "./CardPlaceHolder";

const CardPlaceHolderGroup = () => {
    return (
        <>
            <CardPlaceHolder />
            <CardPlaceHolder />
            <CardPlaceHolder />
            <CardPlaceHolder />
            <CardPlaceHolder />
            <CardPlaceHolder />
            <CardPlaceHolder />
            <CardPlaceHolder />
        </>
    );
};

export default CardPlaceHolderGroup;
