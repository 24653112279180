import React, { useState } from "react";
import API from "../../utils/API";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

const NewProductForm = (props) => {
    const [productTitle, setProductTitle] = useState();
    const [productCategory, setProductCategory] = useState();
    const [productPrice, setProductPrice] = useState();
    const [productImage, setProductImage] = useState();
    const [uploadingProduct, setUploadingProduct] = useState(false);

    const allowedCategories = [
        "alimentos",
        "juguetes",
        "accesorios",
        "higiene y cuidado",
        "ropa",
        "salud y bienestar",
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const createNewProduct = async (event) => {
        event.preventDefault();
        setUploadingProduct(true);

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const formData = new FormData();

        formData.append("title", productTitle);
        formData.append("category", productCategory);
        formData.append("price", productPrice);
        formData.append("image", productImage);
        formData.append("description", "Esta es una descripción");

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-user-id": userId,
                "x-access-token": token,
            },
        };
        API.post("/api/product", formData, config)
            .then((res) => {
                toast.success("Has creado un producto");
                setUploadingProduct(false);
                props.onHide();

                // Refresh the product list
                // API.get("/api/product", config)
                //     .then((res) => {
                //         props.setProducts(res.data);
                //     })
                //     .catch((error) => console.log(error));
            })
            .catch((error) => {
                console.log(error);
                setUploadingProduct(false);
                toast.error(error.response.data.message);
            });
    };
    
    return (
        <>
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">¡Añade un nuevo producto!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3 justify-content-center">
                        <div className="col-md-4">
                            <label htmlFor="product-title" className="form-label">
                                Titulo
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="product-title"
                                onChange={(event) => {
                                    setProductTitle(event.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="product-price" className="form-label">
                                Precio
                            </label>
                            <div className="input-group has-validation">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <i className="fa-solid fa-dollar-sign"></i>
                                </span>
                                <input type="number" className="form-control" id="product-price" aria-describedby="inputGroupPrepend" onChange={(event) => setProductPrice(event.target.value)} required />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="product-category" className="form-label">
                                Categoría
                            </label>
                            <select className="form-select" id="product-category" value={productCategory} onChange={(event) => setProductCategory(event.target.value)} required>
                                <option value="">Selecciona una categoría</option>
                                {/* Mapear las categorías permitidas para generar las opciones del select */}
                                {allowedCategories.map((category) => (
                                    <option key={category} value={category}>
                                        {capitalizeFirstLetter(category)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-9">
                            <label htmlFor="product-images" className="form-label">
                                Fotos de Producto
                            </label>
                            <input className="form-control" type="file" id="product-images" onChange={(event) => setProductImage(event.target.files[0])}></input>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className={uploadingProduct ? "disabled btn btn-danger" : "btn btn-danger"} onClick={props.onHide}>
                        Cerrar
                    </button>
                    {uploadingProduct ? (
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="ms-2">Cargando...</span>
                        </button>
                    ) : (
                        <button className={"btn btn-primary"} onClick={createNewProduct}>Crear producto</button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NewProductForm;
