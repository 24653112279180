import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserImage, setAuthenticated } from "../features/user/userSlice";
import API from "../utils/API";
import toast from "react-hot-toast";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © Petgout "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const theme = createTheme();

export default function SignInSide() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        const body = {
            email,
            password,
        };

        API.post("/api/signin", body)
            .then((res) => {
                const { email, fullname, image } = res.data.userFound;
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("userId", res.data.userFound._id);
                localStorage.setItem("email", email);
                localStorage.setItem("fullname", fullname);
                localStorage.setItem("profilePhoto", image["url"]);
                localStorage.setItem("address", res.data.userFound.address);
                localStorage.setItem("role", res.data.userFound.role);
                localStorage.setItem("cellphone", res.data.userFound.cellphone);
                dispatch(setUserImage());
                dispatch(setAuthenticated());
                navigate("/home");
                toast.success("Iniciaste sesión correctamente");
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.log(error);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: "url(https://images.pexels.com/photos/33053/dog-young-dog-small-dog-maltese.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            ¡Bienvenido a Petgout!
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField margin="normal" required fullWidth id="email" label="Correo Electronico" name="email" autoComplete="email" autoFocus onChange={(event) => setEmail(event.target.value)} />
                            <TextField margin="normal" required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" onChange={(event) => setPassword(event.target.value)} />
                            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Recuerdame" />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
                                Ingresar
                            </Button>
                            <div className="text-center">
                                <Link to="#" variant="body2">
                                    ¿Olvidaste tu contraseña?
                                </Link>
                                <br/>
                                <Link to="/registro" variant="body2">
                                    <span className="text-black">¿Aun no te has registrado?</span> Registrate
                                </Link>
                            </div>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
