import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import './PublicProfileTab.css'
import InfoSection from './InfoSection';
import ProductsSection from './ProductsSection';
import ServicesSection from './ServicesSection';
import WalkRequestSection from './WalkRequestSection';

function PublicProfileTab({ userInfo }) {
  return (
    <div className='d-flex justify-content-center flex-wrap mt-3 mb-4 w-100'  style={{ maxWidth: '600px' }}>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className='w-100 justify-content-center'>
          <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
            <Nav.Link eventKey="first" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Info</Nav.Link>
          </Nav.Item>
          <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
            <Nav.Link eventKey="second" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Productos</Nav.Link>
          </Nav.Item>
          {userInfo.role === "empresa" && (
            <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
              <Nav.Link eventKey="thirst" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Servicios</Nav.Link>
            </Nav.Item>
          )}
          {userInfo.role === "walker" && (
            <Nav.Item className='bg-white mx-1' style={{ borderRadius: '20px' }}>
              <Nav.Link eventKey="fourth" style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>Paseo</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content className='mx-3 my-4 w-100' style={{borderRadius: '20px'}}>
          <Tab.Pane eventKey="first"><InfoSection userInfo={userInfo} /></Tab.Pane>
          <Tab.Pane eventKey="second"><ProductsSection userInfo={userInfo} /></Tab.Pane>
          {userInfo.role === "empresa" && (
            <Tab.Pane eventKey="thirst"><ServicesSection userInfo={userInfo} /></Tab.Pane>
          )}
          {userInfo.role === "walker" && (
            <Tab.Pane eventKey="fourth"><WalkRequestSection userInfo={userInfo} /></Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default PublicProfileTab;