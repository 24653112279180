import React from 'react';
import { useNavigate } from "react-router-dom";
import RatingAndViews from '../shared/RatingAndViews';
import './HomeCard.css';
import { limitarString } from '../../utils/limitarString';

// Import Material UI Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import HomeIcon from '@mui/icons-material/Home';
import MedicationIcon from '@mui/icons-material/Medication';

const HomeCard = ({ data, dataType }) => {
    const navigate = useNavigate();

    const getAttributeBasedOnDataType = (dataType) => {
        switch (dataType) {
            case 'walkers':
                return {
                    name: data.fullname,
                    address: data.address && data.address !== "undefined" ? data.address.charAt(0).toUpperCase() + data.address.slice(1) : "Sin ubicación",
                    ratingList: data.walkerRating,
                    price: data.walkerPrice ? data.walkerPrice.toLocaleString('en-US').replace(',', '.') : '5.000',
                    image: data.image.url,
                    viewCount: data.viewCount
                };
            case 'products':
                // Customize the attributes for products data
                return {
                    name: data.title,
                    address: data.owner.address && data.owner.address !== "undefined" ? data.owner.address.charAt(0).toUpperCase() + data.owner.address.slice(1) : "Sin ubicación",
                    ratingList: data.valuation,
                    price: data.price ? data.price.toLocaleString('en-US').replace(',', '.') : '0',
                    image: data.image.url,
                    viewCount: data.viewCount
                };
            case 'services':
                // Customize the attributes for services data
                return {
                    name: data.title,
                    address: data.owner.address && data.owner.address !== "undefined" ? data.owner.address.charAt(0).toUpperCase() + data.owner.address.slice(1) : "Sin ubicación",
                    ratingList: data.valuation,
                    price: data.price ? data.price.toLocaleString('en-US').replace(',', '.') : '0',
                    image: data.image.url,
                    viewCount: data.viewCount
                };
            case 'companies':
                // Customize the attributes for company data
                return {
                    name: data.fullname,
                    address: data.address && data.address !== "undefined" ? data.address.charAt(0).toUpperCase() + data.address.slice(1) : "Sin ubicación",
                    ratingList: data.sellerRating,
                    image: data.image.url,
                    viewCount: data.viewCount,
                    category: data.companyCategory
                };
            default:
                return null;
        }
    };

    const cardData = getAttributeBasedOnDataType(dataType);

    if (!cardData) {
        // Handle invalid dataType here or return null if you want to render nothing
        return null;
    }

    return (
        <div className='card p-2 my-2 bg-white shadow border home-card-container'
            style={{ borderRadius: '20px' }}
            onClick={
                dataType !== 'products' && dataType !== 'services' ? () => navigate("/perfil/" + data._id) : null
            }>
            <div className='card-body p-0 d-flex flex-column align-items-center home-card-content' style={{ textAlign: 'left', overflow: 'hidden' }}>
                <img className="home-card-image" src={cardData.image} alt={`${cardData.name} foto`} />
                <h6 className='mb-0 w-100 ms-2 no-wrap' style={{fontWeight: 'bold'}}>
                    {limitarString(cardData.name)}
                </h6>
                <div className="d-flex align-items-center w-100" style={{ color: 'grey', marginTop: '5px' }}>
                    <LocationOnIcon style={{ marginRight: '1px' }} fontSize='small' />
                    <h6 className="mb-0  ">
                        {limitarString(cardData.address)}
                    </h6>
                </div>
                <div className='w-100'>
                    <RatingAndViews ratingList={cardData.ratingList} viewCount={cardData.viewCount} />
                </div>
                {
                    dataType !== 'companies' && (
                        <h6 className='text-primary mb-0 w-100'><b>${cardData.price}{dataType === 'walkers' && " / Hora"}</b></h6>
                    )
                }
                {
                    dataType === 'companies' && (
                        <div className='bg-white m-auto p-0 text-center' style={{
                            width: '28px', height: '28px', overflow: 'hidden', borderRadius: '50%', position: 'absolute', right: '15px', top: '15px',
                            boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"
                        }}>
                            {cardData.category === 'tienda' && (
                                <LocalGroceryStoreIcon fontSize='small' />
                            )}
                            {cardData.category === 'veterinaria' && (
                                <MedicationIcon fontSize='small' />
                            )}
                            {cardData.category === 'guarderia' && (
                                <HomeIcon fontSize='small' />
                            )}
                            {cardData.category === 'peluqueria' && (
                                <ContentCutIcon fontSize='small' />
                            )}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default HomeCard;
