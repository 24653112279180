import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useEffect } from 'react';

export default function RatingOneStar({ stars }) {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setValue(stars);
  }, [])

  return (
    <Box>
      <Rating name="read-only" value={value} readOnly />
    </Box>
  );
}