import React, { useState, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'; // Import Swiper core components
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper React components

// Install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Slider = () => {
  const slideDataDesktop = [
    {
      id: 4,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1698627543/banners%20desktop/banner-4.png',
    },
    {
      id: 1,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1696380523/banners%20desktop/banner-1.png',
    },
    {
      id: 2,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1692888708/banners%20desktop/banner-2.svg',
    },
    {
      id: 3,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1692853148/banners%20desktop/banner-3.svg',
    },
  ];
  const slideDataMobile = [
    {
      id: 4,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1698627120/banners%20mobile/banner-4.png',
    },
    {
      id: 1,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1696380431/banners%20mobile/banner-1.png',
    },
    {
      id: 2,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1696380456/banners%20mobile/banner-2.png',
    },
    {
      id: 3,
      imageUrl: 'https://res.cloudinary.com/dnslkysy1/image/upload/v1692852869/banners%20mobile/banner-3.svg',
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slidesToShow = windowWidth > 720 ? slideDataDesktop : slideDataMobile;

  return (
    <Swiper
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      className='slider-main-banner'
    >
      {slidesToShow.map((slide) => (
        <SwiperSlide key={slide.id}>
          <img src={slide.imageUrl} alt={slide.caption} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
