import React from 'react';
import { useTheme } from '@mui/material/styles';

const Logo = () => {
    const theme = useTheme();

    return (
        <>
            <img
                src="/images/petgout-logo.png"
                alt="petgout-logo"
                style={{
                    marginRight: theme.spacing(1),
                    maxHeight: '2rem'
                }}
            />
        </>
    );
};

export default Logo;
