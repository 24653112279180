import React, { useEffect, useState } from 'react'
import ServiceCard from '../services/ServiceCard'
import API from "../../utils/API";
import NewServiceForm from './NewServiceForm';
import ModalDetailProduct from '../marketplace/ModalDetailProduct';

const UserServices = ({ userInfo }) => {
    const [services, setServices] = useState([]);
    const [show, setShow] = useState(false);
    const [serviceSelected, setServiceSelected] = useState();
    const [newServiceModal, setNewServiceModal] = useState(false);

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const headers = {
        "x-access-token": token,
        "x-user-id": userId,
    };

    useEffect(() => {
        setNewServiceModal(false);
        if (userInfo && userInfo._id) getServices();
    }, [userInfo])

    const getServices = async () => {
        try {
            const response = await API.get(`/api/services/${userInfo._id}`, { headers });
            setServices(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleShow = (service) => {
        setServiceSelected(service);
        setShow(true);
    };

    return (
        <>
            <div className="d-flex flex-wrap align-items-center" style={{ justifyContent: 'center' }}>
                <button className="btn btn-primary mb-4" onClick={() => setNewServiceModal(true)}>Crear Servicio</button>
            </div>
            <div className='d-flex flex-wrap justify-content-between'>
                {services.length > 0 ? (
                    services.map((service) => (
                        <ServiceCard key={service._id} service={service} isForPublicProfile={true} onClick={() => handleShow(service)} />
                    ))
                ) : (
                    <div className='w-100 h-100 shadow border bg-white p-3 d-flex align-items-center justify-content-center' style={{ borderRadius: '20px' }}>
                        <h5 className='mb-0'>Esta cuenta aún no cuenta con servicios publicados</h5>
                    </div>
                )}
            </div>
            <ModalDetailProduct isService={true} userIsOwner={true} show={show} setShow={setShow} serviceSelected={serviceSelected} />
            <NewServiceForm show={newServiceModal} onHide={() => setNewServiceModal(false)} />
        </>
    )
}

export default UserServices;
