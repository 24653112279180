import React from 'react';
import { useNavigate } from "react-router-dom";
import "./Footer.css"

const Footer = () => {
    const navigate = useNavigate();

    return (
        <>
            <div style={{ height: '8rem' }}></div>
            <footer className="footer">
                <div className="social-links">
                    <a href="https://www.instagram.com/petgout/" target="_blank" rel="noopener noreferrer" className="social-link me-3">
                        <i className="fab fa-instagram social-icon"></i>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100094156327302" target="_blank" rel="noopener noreferrer" className="social-link me-3">
                        <i className="fab fa-facebook social-icon"></i>
                    </a>
                    <a href="https://www.tiktok.com/@petgou1" target="_blank" rel="noopener noreferrer" className="social-link me-3">
                        <i className="fab fa-tiktok social-icon"></i>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=573045960486" target="_blank" rel="noopener noreferrer" className="social-link">
                        <i className="fab fa-whatsapp social-icon"></i>
                    </a>
                </div>
                <div className="navigation-links">
                    <span onClick={() => navigate("/terminos-y-condiciones/")} className="navigation-link">
                        Términos y condiciones
                    </span>
                    <span onClick={() => navigate("/preguntas-frecuentes/")} className="navigation-link">
                        Preguntas frecuentes
                    </span>
                </div>
            </footer>
        </>
    );
};

export default Footer;
