import React, { useEffect, useState } from 'react';
import './CommentBox.css'
import API from '../../utils/API';
import { toast } from 'react-hot-toast';

// Moment imports
import moment from 'moment';
import 'moment/locale/es';

// Get user information from localStorage
const token = localStorage.getItem('token');
const userId = localStorage.getItem('userId');

// Create the headers configuration
const config = {
  headers: {
    'content-type': 'application/json', // Change to JSON content type
    'x-user-id': userId,
    'x-access-token': token,
  },
};

const Comment = ({ comment, onReply, activeReplyCommentId, setActiveReplyCommentId }) => {
  const { user, text, replies, createdAt } = comment;

  const formatDate = (date) => {
    moment.locale('es');
    var momentDate = moment(date);
    return momentDate.fromNow();
  };

  return (
    <div className="comment mt-3">
      <div className="user-info">
        <img src={user.image.url} alt={`Profile of ${user.fullname}`} className="user-photo" />
        <div>
          <p className="username mb-0">{user.fullname}</p>
          <p className='text-muted mb-0'>- {formatDate(createdAt)}</p>
        </div>
      </div>
      <p className="comment-text">{text}</p>
      {
        activeReplyCommentId === comment._id ? (
          <CommentForm onSubmit={(text) => onReply(comment._id, text)} />
        ) : onReply == null ? (
          <></>
        ) : (
          <button className="btn-petgout" onClick={() => setActiveReplyCommentId(comment._id)}>Responder</button>
        )
      }
      <div className="replies">
        {replies.map((reply, index) => (
          <Comment
            key={index}
            comment={reply}
            onReply={null}
            activeReplyCommentId={activeReplyCommentId}
          />
        ))}
      </div>
    </div>
  );
};

const CommentForm = ({ onSubmit }) => {
  const [commentText, setCommentText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(commentText);
    setCommentText('');
  };

  return (
    <form className="comment-form" onSubmit={handleSubmit}>
      <textarea
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        placeholder="Escribe tu comentario..."
        required
      />
      <button className='mt-2 btn-petgout'>Enviar</button>
    </form>
  );
};

const CommentBox = ({ targetId }) => {
  const [comments, setComments] = useState([]);
  const [activeReplyCommentId, setActiveReplyCommentId] = useState(null); // State for the active reply form

  useEffect(() => {
    loadComments();
  }, []);

  const loadComments = () => {
    // Replace ':targetId' with the actual target ID for comments
    API.get(`/api/comments/${targetId}`, config)
      .then((res) => {
        setComments(res.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error cargando comentarios');
      });
  };

  const handleCommentSubmit = (text) => {
    const newComment = {
      text: text,
      target: targetId,
    };

    API.post('/api/comments', newComment, config)
      .then((res) => {
        console.log(res);
        toast.success('Comentario enviado');
        loadComments(); // Refresh comments after submitting a new one
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error en comentario');
      });
  };

  const handleReply = (targetCommentId, text) => {
    const newReply = {
      text: text,
      target: targetCommentId,
    };

    API.post('/api/comments-reply', newReply, config)
      .then((res) => {
        console.log(res);
        toast.success('Respuesta enviada');
        loadComments(); // Refresh comments after submitting a new reply
        setActiveReplyCommentId(null); // Hide the reply form after sending a reply
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error en respuesta');
      });
  };

  return (
    <div className="comment-box">
      <h2>Comentarios</h2>
      <CommentForm onSubmit={handleCommentSubmit} />
      <div className="comment-list">
        {comments.map((comment, index) => (
          <Comment
            key={index}
            comment={comment}
            onReply={handleReply}
            activeReplyCommentId={activeReplyCommentId}
            setActiveReplyCommentId={setActiveReplyCommentId}
          />
        ))}
      </div>
    </div>
  );
};

export default CommentBox;