import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { setUserImage } from "../../features/user/userSlice";
import API from "../../utils/API";

const UpdateProfilePhotoModal = (props) => {
    const [newProfilePhoto, setNewProfilePhoto] = useState();
    const [userId, setUserId] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        setUserId(localStorage.getItem("userId"));
    }, []);

    const updatePhoto = async (event) => {
        try {
            event.preventDefault();

            const token = localStorage.getItem("token");
            const formData = new FormData();

            formData.append("image", newProfilePhoto);

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "x-user-id": userId,
                    "x-access-token": token,
                },
            };

            API.put(`/api/user/${userId}`, formData, config)
                .then((res) => {
                    localStorage.setItem("profilePhoto", res.data);
                    dispatch(setUserImage());
                    toast.success("Añadiste una foto de perfil");
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.response.data.message);
                });
            props.onHide();
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Elije una imagen de perfil
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formFile">
                    <Form.Control
                        type="file"
                        onChange={(event) => setNewProfilePhoto(event.target.files[0])}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={props.onHide}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={updatePhoto}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateProfilePhotoModal;
