import React from 'react'
import TextField from "@mui/material/TextField";
import CellPhoneField from "../../components/profile/CellPhoneField";
import WalkerPriceField from "../../components/profile/WalkerPriceField";
import DescriptionField from "../../components/profile/DescriptionField";
import AddressField from "../../components/profile/AddressField";

const UserInfo = ({ userInfo, setUser, setUpdateUserAddressModal }) => {

    return (
        <div className="d-flex flex-wrap justify-content-between">
            <TextField
                id="outlined-read-only-input"
                label="Nombre"
                value={userInfo.fullname || ''}
                className="my-2 profile-data-field"
                InputProps={{
                    readOnly: true
                }}
            />
            <TextField
                id="outlined-read-only-input"
                label="Email"
                value={userInfo.email || ''}
                className="my-2 profile-data-field"
                InputProps={{
                    readOnly: true,
                }}
            />
            <AddressField address={userInfo.address} setUpdateUserAddressModal={setUpdateUserAddressModal} />
            <WalkerPriceField price={userInfo.walkerPrice} setUser={setUser} />
            <DescriptionField description={userInfo.description} setUser={setUser} />
            <CellPhoneField cellphone={userInfo.cellphone} setUser={setUser} />
        </div>
    )
}

export default UserInfo