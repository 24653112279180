import React, { useEffect, useState } from "react";
import CardPlaceHolderGroup from "../components/shared/CardPlaceHolderGroup";
import API from "../utils/API";
import { useNavigate } from "react-router-dom";

// Imports Material UI
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import HomeIcon from '@mui/icons-material/Home';
import MedicationIcon from '@mui/icons-material/Medication';

import "./Companies.css";
import CompanyCard from "../components/companies/CompanyCard";

const Companies = () => {
    const [companies, setCompanies] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState("cualquiera");
    const [searchBarFilter, setSearchBarFilter] = useState("")
    const [cardPlaceholder, setCardPlaceholder] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        API.get(`/api/companies`, { headers }).then((res) => {
            setCompanies(res.data);
            setCardPlaceholder(false);
        });
    }, []);

    function applyCategoryFilter(value) {
        if (categoryFilter.toLowerCase() === "cualquiera") {
            return true;
        }
        if (value["companyCategory"] === undefined) {
            return false;
        }
        if (value["companyCategory"].toLowerCase() === categoryFilter.toLowerCase()) {
            return true;
        }
        return false;
    }

    function applySearchBarFilter(value) {
        // Verify the item match with the searchbar characters
        if (searchBarFilter === "" || (value["fullname"].toLowerCase().includes(searchBarFilter.toLowerCase()) && searchBarFilter !== "")) {
            return true;
        }
        return false;
    }

    const handleChangeCategoryFilter = (event) => {
        setCategoryFilter(event.target.value);
    };

    const handleChangeSearchBarFilter = (event) => {
        setSearchBarFilter(event.target.value);
    };

    const companiesAfterFilter = companies.filter(value => applyCategoryFilter(value) && applySearchBarFilter(value));

    return (
        <>
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-evenly mt-2" >
                    <FormControl className="my-3 marketplace-filter-field">
                        <TextField id="demo-simple-select" value={searchBarFilter} label="Buscador" onChange={event => handleChangeSearchBarFilter(event)}></TextField>
                    </FormControl>
                    <FormControl className="marketplace-filter-field">
                        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                        <Select id="demo-simple-select" value={categoryFilter} label="Categoria" onChange={handleChangeCategoryFilter}>
                            <MenuItem value="cualquiera">Cualquiera</MenuItem>
                            <MenuItem value="tienda"><LocalGroceryStoreIcon /> Tienda</MenuItem>
                            <MenuItem value="veterinaria"><MedicationIcon /> Veterinaria</MenuItem>
                            <MenuItem value="peluqueria"><ContentCutIcon /> Peluqueria</MenuItem>
                            <MenuItem value="guarderia"><HomeIcon /> Guardería</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <section className="d-flex flex-wrap" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    {!cardPlaceholder &&
                        companiesAfterFilter.map((company) => (
                            <CompanyCard key={company._id} company={company} />
                        ))}
                    {cardPlaceholder && <CardPlaceHolderGroup />}
                </section>
            </div>
        </>
    );
};

export default Companies;
