import React from 'react'
import './Foundations.css';

const Foundations = () => {
    return (
        <div class="blog">
            <div class="container">
                <div class="section-header text-center mb-4">
                    <h2>¡Nueva Alianza!</h2>
                </div>
                <div class="col-md-6 m-auto">
                    <div class="blog-item">
                        <div class="blog-img">
                            <img src="https://www.fundaciontepa.org/wp-content/uploads/2020/09/imagen-fondo-inicio3.jpg" alt="Blog" />
                        </div>
                        <div class="blog-content">
                            <h2 class="blog-title">Fundación TEPA</h2>
                            {/* <div class="blog-meta">
                                <p><i class="far fa-user"></i>Admin</p>
                                <p><i class="far fa-list-alt"></i>Food</p>
                                <p><i class="far fa-calendar-alt"></i>01-Jan-2045</p>
                                <p><i class="far fa-comments"></i>10</p>
                            </div> */}
                            <div class="blog-text">
                                <p>
                                Buscamos proteger la vida de perros y gatos en estado de maltrato y abandono. Les brindamos alimentos, medicamentos y albergue, con el fin de mejorar su calidad de vida y conseguirles un hogar.
                                </p>
                                <a class="btn-petgout text-center" href="https://www.fundaciontepa.org/">Visita su sitio web</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Foundations