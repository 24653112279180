import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

const NewServiceForm = (props) => {
    const [serviceTitle, setServiceTitle] = useState();
    const [serviceCategory, setServiceCategory] = useState();
    const [servicePrice, setServicePrice] = useState();
    const [serviceImage, setServiceImage] = useState();
    const [uploadingService, setUploadingService] = useState(false);

    const allowedCategories = [
        "guarderia",
        "aseo y estetica",
        "adiestramiento y educación",
        "salud y cuidado veterinario",
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const createNewService = async (event) => {
        event.preventDefault();
        setUploadingService(true);

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const formData = new FormData();

        formData.append("title", serviceTitle);
        formData.append("category", serviceCategory);
        formData.append("price", servicePrice);
        formData.append("image", serviceImage);
        formData.append("description", "Esta es una descripción");

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-user-id": userId,
                "x-access-token": token,
            },
        };

        API.post("/api/service", formData, config)
            .then((res) => {
                toast.success("Has creado un servicio");
                setUploadingService(false);
                props.onHide();

                // Refresh the service list
                // API.get("/api/service", config)
                //     .then((res) => {
                //         props.setServices(res.data);
                //     })
                //     .catch((error) => console.log(error));
            })
            .catch((error) => {
                console.log(error);
                setUploadingService(false);
                toast.error(error.response.data.message);
            });
    };
    
    return (
        <>
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">¡Añade un nuevo servicio!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="service-title" className="form-label">
                                Titulo
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="service-title"
                                onChange={(event) => {
                                    setServiceTitle(event.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="service-price" className="form-label">
                                Precio
                            </label>
                            <div className="input-group has-validation">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <i className="fa-solid fa-dollar-sign"></i>
                                </span>
                                <input type="number" className="form-control" id="service-price" aria-describedby="inputGroupPrepend" onChange={(event) => setServicePrice(event.target.value)} required />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="service-category" className="form-label">
                                Categoria
                            </label>
                            <select className="form-select" id="service-category" value={serviceCategory} onChange={(event) => setServiceCategory(event.target.value)} required>
                                <option value="">Selecciona una categoría</option>
                                {/* Mapear las categorías permitidas para generar las opciones del select */}
                                {allowedCategories.map((category) => (
                                    <option key={category} value={category}>
                                        {capitalizeFirstLetter(category)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-9">
                            <label htmlFor="service-images" className="form-label">
                                Fotos de Servicio
                            </label>
                            <input className="form-control" type="file" id="service-images" onChange={(event) => setServiceImage(event.target.files[0])}></input>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className={uploadingService ? "disabled btn btn-danger" : "btn btn-danger"} onClick={props.onHide}>
                        Cerrar
                    </button>
                    {uploadingService ? (
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="ms-2">Cargando...</span>
                        </button>
                    ) : (
                        <button className={uploadingService ? "btn btn-primary" : "btn btn-primary"} onClick={createNewService}>Crear servicio</button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NewServiceForm;
