export const limitarString = (str) => {
    // Obtener el ancho de la pantalla
    const screenWidth = window.innerWidth;

    // Definir los límites máximos según el ancho de la pantalla
    let limiteMaximo = screenWidth >= 768 ? 30 : 20

    // Verificar si el string excede el límite y truncar si es necesario
    if (str.length <= limiteMaximo) {
        return str;
    }

    return str.slice(0, limiteMaximo - 3) + "...";
}
