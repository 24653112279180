import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import './MapboxComponent.css';

const MapboxComponent = ({ height, coordinates, width }) => {
  useEffect(() => {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiZGFuamZhciIsImEiOiJjbGtlbTJpdXUxMnZrM25xdHIxOHNnZXRkIn0.kVTfQYhtt4xd2e0eefdq6Q';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: coordinates,
      zoom: 12,
    });

    map.on('load', () => {
      // Add an image to use as a custom marker
      map.loadImage(
        'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        (error, image) => {
          if (error) throw error;
          map.addImage('custom-marker', image);
          // Add a GeoJSON source with 1 point (Mi ubicación)
          map.addSource('point', {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: coordinates,
              },
            },
          });

          // Add a symbol layer
          map.addLayer({
            id: 'points',
            type: 'symbol',
            source: 'point',
            layout: {
              'icon-image': 'custom-marker',
              // Get the title name from the source's "title" property
              'text-field': ['get', 'title'],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-offset': [0, 1.25],
              'text-anchor': 'top',
            },
          });
        }
      );
    });

    return () => map.remove();
  }, []);

  return <div id="map" className="map-container" style={{ height, width }} />;
};

export default MapboxComponent;
