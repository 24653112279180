import React, { useEffect, useState } from 'react'
import API from "../../utils/API";
import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import toast from "react-hot-toast";

// Components imports
import CardPlaceHolder from "../../components/shared/CardPlaceHolder";
import WalkRequestStepper from "../../components/profile/Stepper";
import IOSSwitch from "../../components/profile/IOSSwitch";

// Material UI imports
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Rating from '@mui/material/Rating';

// React bootstrap imports
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

// Swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

// Redux imports
import { useDispatch } from "react-redux";
import { countWalkRequests } from "../../features/user/userSlice";

const WalkRequests = ({
    setPetSelected,
    cardPlaceholder,
    setShow,
    userInfo
}) => {
    const [displayValorationInput, setDisplayValorationInput] = useState(true)
    const [newValorationToBackend, setNewValorationToBackend] = useState(0);
    const [walkerAvailability, setWalkerAvailability] = useState(false);
    const [pendingWalkRequests, setPendingWalkRequests] = useState([]);
    const [acceptedWalkRequests, setAcceptedWalkRequests] = useState([])
    const [completedWalkRequests, setCompletedWalkRequests] = useState([])
    const [pendingConfirmWalkRequests, setPendingConfirmWalkRequests] = useState([])

    const dispatch = useDispatch();

    useEffect(() => {
        getWalkRequests();
        if (userInfo.walkerAvailable !== undefined) {
            setWalkerAvailability(userInfo.walkerAvailable)
        }
    }, [userInfo])

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const headers = {
        "x-access-token": token,
        "x-user-id": userId,
    };

    const changeWalkerAvailability = () => {
        try {
            API.put(`/api/walkers/${userId}/availability`, {}, { headers })
                .then((res) => {
                    setWalkerAvailability(res.data.walker.walkerAvailable);
                    localStorage.setItem("walkerAvailability", res.data.walker.walkerAvailable);
                    res.data.walker.walkerAvailable ? toast.success("Cambiaste tu estado a Disponible") : toast.success("Cambiaste tu estado a Ausente");
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            toast.error("Hubo un error al cambiar la disponibilidad");
        }
    };

    const handleShowPetModal = (pet) => {
        setPetSelected(pet);
        setShow(true);
    };

    const formatToFullDate = (UTCDate) => {
        moment.locale('es');
        const date = moment(UTCDate).fromNow();
        return date
    };

    const takeTimeFromDateType = (UTCDate) => {
        moment.locale('es');
        const hour = moment(UTCDate).format('h:mm a');
        const date = moment(UTCDate).format('LL');
        return `${date} a las ${hour}`
    };

    const walkerConfirmWalkRequestCompleted = (walkId, responseToWalkRequest) => {
        const walkAccepted = responseToWalkRequest ? "por-confirmar" : "rechazado-por-paseador"

        const data = {
            "walkId": walkId,
            "newWalkRequestStatus": walkAccepted
        }

        API.put(`/api/walk-request`, data, { headers })
            .then((res) => {
                console.log(res);
                walkAccepted !== "cancelado"
                    ? (
                        toast.success(`Has confirmado que el paseo fue realizado`)
                    ) : (
                        toast.error(`El paseo para no fue realizado`)
                    );
                getWalkRequests();
            })
            .catch((err) => {
                console.log(err);
                toast.error(`Hubo un error al completar el paseo`);
            });
    }

    const petOwnerConfirmWalkRequestCompleted = (walkId, responseToWalkRequest) => {
        setDisplayValorationInput(true)
        const walkAccepted = responseToWalkRequest ? "completo" : "rechazado-por-cliente"

        const data = {
            "walkId": walkId,
            "newWalkRequestStatus": walkAccepted
        }

        API.put(`/api/walk-request`, data, { headers })
            .then((res) => {
                console.log(res);
                walkAccepted !== "cancelado"
                    ? (
                        toast.success(`Has confirmado que el paseo fue realizado`)
                    ) : (
                        toast.error(`El paseo para no fue realizado`)
                    );
                setDisplayValorationInput(false)
                getWalkRequests();
            })
            .catch((err) => {
                console.log(err);
                setDisplayValorationInput(false)
                toast.error(`Hubo un error al completar el paseo`);
            });
    }

    const sendResponseToWalkRequest = (walkId, responseToWalkRequest) => {
        const walkAccepted = responseToWalkRequest ? "aceptado" : "rechazado"

        const data = {
            "walkId": walkId,
            "newWalkRequestStatus": walkAccepted
        }

        API.put(`/api/walk-request`, data, { headers })
            .then((res) => {
                console.log(res);
                toast.success(`Has ${walkAccepted} la solicitud de paseo de ${res.data.petOwner.fullname}`);
                getWalkRequests();
            })
            .catch((err) => {
                console.log(err);
                toast.error(`Hubo un error al responder la solicitud de paseo`);
            });
    };

    const valorateWalker = (walkerId) => {
        const data = {
            "walkerValoration": newValorationToBackend,
            "walkerId": walkerId
        }

        API.put(`/api/walkers/${userId}/valorate`, data, { headers })
            .then((res) => {
                console.log(res);
                toast.success(`Has valorado al paseador`);
            })
            .catch((err) => {
                console.log(err);
                toast.error(`Hubo un error al valorar al paseador`);
            });
    };

    const sendCompleteWalkAndValorationToBackend = (targetId, walkerId) => {
        petOwnerConfirmWalkRequestCompleted(targetId, true)
        valorateWalker(walkerId)
    }

    const getWalkRequests = () => {
        // Get walk requests
        API.get(`/api/walk-request`, { headers })
            .then((res) => {
                // console.log(res.data);
                const allRequests = res.data;
                const pendingRequests = allRequests.filter(request => request.status === 'pendiente');
                setPendingWalkRequests(pendingRequests);
                const acceptedRequests = allRequests.filter(request => request.status === 'aceptado');
                setAcceptedWalkRequests(acceptedRequests);
                const completedRequests = allRequests.filter(request => request.status === 'completo');
                setCompletedWalkRequests(completedRequests);
                const pendingConfirmRequests = allRequests.filter(request => request.status === 'por-confirmar');
                setPendingConfirmWalkRequests(pendingConfirmRequests);

                // Update Navbar Counter
                dispatch(countWalkRequests(pendingRequests.length + pendingConfirmRequests.length));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="w-100 mt-3">
            {userInfo.role === "walker" && (
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="w-100 my-2 border p-2 shadow text-center profile-walk-availability h-100 p-4" style={{ borderRadius: '20px' }}>
                        <h4 className="mt-2">¿Estas Disponible para pasear?</h4>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                            <h5 className="mb-0">No</h5>
                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={walkerAvailability} onChange={changeWalkerAvailability} />} />
                            <h5 className="mb-0">Si</h5>
                        </Stack>
                    </div>
                    {/* {
                        pendingConfirmWalkRequests.length > 0 && userInfo.role === 'walker' && (
                            <>
                                <h4 className="text-center mt-3 mx-auto">Paseos Realizados por Confirmar ({pendingConfirmWalkRequests.length} en total)
                                    <OverlayTrigger trigger="click" placement="top" overlay={walkerPendingConfirmWalkPopover} rootClose="true">
                                        <ContactSupportIcon className="d-inline-block ms-2" style={{ cursor: 'pointer' }} />
                                    </OverlayTrigger>
                                </h4>
                                <Swiper
                                    navigation={true}
                                    modules={[Navigation]}
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    style={{ minHeight: '5rem', maxHeight: '26rem' }}
                                    autoHeight={true}
                                >
                                    <Stack sx={{ width: "100%" }} spacing={2}>
                                        {pendingConfirmWalkRequests.map((walkRequest) => (
                                            <SwiperSlide key={walkRequest._id}>
                                                <Alert icon={false} variant="outlined" severity="success" className="shadow my-3">
                                                    <p>
                                                        El {takeTimeFromDateType(walkRequest.schedule)} realizaste un paseo pendiente por confirmar.
                                                    </p>
                                                    <hr className="mt-0" />
                                                    <Link to={"/perfil/" + walkRequest.petOwner._id} className="d-flex align-items-center justify-content-center mb-3">
                                                        <img src={walkRequest.petOwner.image.url} className="border rounded-circle me-2" alt="Avatar" style={{ height: "2.7rem", width: "2.7rem" }} />
                                                        <strong className="text-dark mb-0">{walkRequest.petOwner.fullname}</strong>
                                                    </Link>
                                                    <WalkRequestStepper step={1} />
                                                    <hr className="mt-0" />
                                                    <p className="mb-0">Pendiente por confirmar desde {formatToFullDate(walkRequest.updatedAt)}</p>
                                                </Alert>
                                            </SwiperSlide>
                                        ))}
                                    </Stack>
                                </Swiper>
                            </>
                        )
                    } */}
                    {
                        acceptedWalkRequests.length > 0
                        && (
                            <div className="w-100 my-2 px-2 py-2 h-100">
                                <h4 className="text-center">Paseos Aceptados ({acceptedWalkRequests.length} en total)
                                    <OverlayTrigger trigger="click" placement="top" overlay={acceptedWalksPopover} rootClose="true">
                                        <ContactSupportIcon className="d-inline-block ms-2" style={{ cursor: 'pointer' }} />
                                    </OverlayTrigger>
                                </h4>
                                <Swiper
                                    navigation={true}
                                    modules={[Navigation]}
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    style={{ minHeight: '5rem', maxHeight: '26rem' }}
                                    autoHeight={true}
                                >
                                    <Stack sx={{ width: "100%" }} spacing={2}>
                                        {acceptedWalkRequests.map((walkRequest) => (
                                            <SwiperSlide key={walkRequest._id}>
                                                <Alert icon={false} variant="outlined" severity="success" className="shadow my-3">
                                                    <>
                                                        <p className="mb-0">
                                                            ¿Has completado el paseo?
                                                        </p>
                                                        <div className="buttons d-inline mx-3">
                                                            <i className="fa-solid fa-circle-check mx-2 my-2 accept-walk-button" id={walkRequest._id} onClick={e => walkerConfirmWalkRequestCompleted(e.target.id, true)}></i>
                                                            <i className="fa-solid fa-circle-xmark mx-2 my-2 reject-walk-button" id={walkRequest._id} onClick={e => walkerConfirmWalkRequestCompleted(e.target.id, false)}></i>
                                                        </div>
                                                    </>
                                                    <hr className="mt-0" />
                                                    <Link to={"/perfil/" + walkRequest.petOwner._id} className="d-flex align-items-center justify-content-center mb-3">
                                                        <img src={walkRequest.petOwner.image.url} className="border rounded-circle me-2" alt="Avatar" style={{ height: "2.7rem", width: "2.7rem" }} />
                                                        <strong className="text-dark mb-0">{walkRequest.petOwner.fullname}</strong>
                                                    </Link>
                                                    <p className="mb-3"><strong>Horario:</strong> {takeTimeFromDateType(walkRequest.schedule)}</p>
                                                    <WalkRequestStepper step={0} />
                                                    <hr className="mt-0" />
                                                    <p className="mb-0">Aceptado {formatToFullDate(walkRequest.updatedAt)}</p>
                                                </Alert>
                                            </SwiperSlide>
                                        ))}
                                    </Stack>
                                </Swiper>
                            </div>
                        )
                    }
                    {
                        pendingWalkRequests.length > 0
                        && (
                            <div className="text-center mx-auto mb-4">
                                <h4 className="text-center mt-3">Solicitudes De Paseo</h4>
                                <Stack sx={{ width: "100%" }} spacing={2}>
                                    {pendingWalkRequests.map((walkRequest) => (
                                        <Alert icon={false} variant="outlined" severity="info" className="mx-auto" key={walkRequest._id}>
                                            <p className="d-inline">
                                                Tienes una solicitud de paseo de {walkRequest.petOwner.fullname} para el {takeTimeFromDateType(walkRequest.schedule)}
                                            </p>
                                            <br />
                                            <div className="buttons d-inline mx-3">
                                                <i className="fa-solid fa-circle-check mx-2 my-2 accept-walk-button" id={walkRequest._id} onClick={e => sendResponseToWalkRequest(e.target.id, true)}></i>
                                                <i className="fa-solid fa-circle-xmark mx-2 my-2 reject-walk-button" id={walkRequest._id} onClick={e => sendResponseToWalkRequest(e.target.id, false)}></i>
                                            </div>
                                            <br />
                                            <p className="mb-0 d-inline">Solicitado {formatToFullDate(walkRequest.createdAt)}</p>
                                        </Alert>
                                    ))}
                                </Stack>
                            </div>
                        )
                    }
                    {
                        completedWalkRequests.length > 0
                        && (
                            <div className="w-100 my-2 border rounded px-2 py-2 shadow text-center profile-walk-requests-list h-100">
                                <h4 className="text-center">Paseos Completados ({completedWalkRequests.length} en total)
                                    <OverlayTrigger trigger="click" placement="top" overlay={completeWalkPopover} rootClose="true">
                                        <ContactSupportIcon className="d-inline-block ms-2" style={{ cursor: 'pointer' }} />
                                    </OverlayTrigger>
                                </h4>

                                <Stack sx={{ width: "100%" }} spacing={2}>
                                    {completedWalkRequests.map((walkRequest) => (
                                        <Alert variant="outlined" severity="success" key={walkRequest._id}>
                                            <p className="mb-0">
                                                Has completado el paseo reservado para {walkRequest.petOwner.fullname} el dia {takeTimeFromDateType(walkRequest.schedule)}
                                            </p>
                                        </Alert>
                                    ))}
                                </Stack>
                            </div>
                        )
                    }
                </div>
            )}
            {userInfo.role === "cliente" && (
                pendingConfirmWalkRequests.length > 0
                && (
                    <>
                        <h4 className="text-center">Paseos Realizados por Confirmar ({pendingConfirmWalkRequests.length} en total)
                            <OverlayTrigger trigger="click" placement="top" overlay={clientPendingConfirmWalkPopover} rootClose="true">
                                <ContactSupportIcon className="d-inline-block ms-2" style={{ cursor: 'pointer' }} />
                            </OverlayTrigger>
                        </h4>
                        <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            slidesPerView={1}
                            spaceBetween={10}
                            style={{ minHeight: '5rem', maxHeight: '26rem' }}
                            autoHeight={true}
                        >
                            <Stack sx={{ width: "100%" }} spacing={2}>
                                {pendingConfirmWalkRequests.map((walkRequest) => (
                                    <SwiperSlide key={walkRequest._id}>
                                        <Alert icon={false} variant="outlined" severity="success" className="shadow my-3">
                                            <p>
                                                Cuentas con un paseo programado para el {takeTimeFromDateType(walkRequest.schedule)} con el paseador {walkRequest.walker.fullname}.
                                            </p>
                                            <hr className="mt-0" />
                                            <Link to={"/perfil/" + walkRequest.walker._id} className="d-flex align-items-center justify-content-center mb-3">
                                                <img src={walkRequest.walker.image.url} className="border rounded-circle me-2" alt="Avatar" style={{ height: "2.7rem", width: "2.7rem" }} />
                                                <strong className="text-dark mb-0">{walkRequest.walker.fullname}</strong>
                                            </Link>
                                            <WalkRequestStepper step={1} />
                                            <hr className="mt-0" />
                                            <>
                                                {
                                                    displayValorationInput ? (
                                                        <>
                                                            <p className="mb-0">
                                                                Por favor valora la experiencia y confirma presionando el boton
                                                            </p>
                                                            <div className="my-2">
                                                                <Rating
                                                                    name="simple-controlled"
                                                                    value={newValorationToBackend}
                                                                    onChange={(event, newValue) => {
                                                                        setNewValorationToBackend(newValue);
                                                                    }}
                                                                />
                                                                < br />
                                                                <button className="btn btn-success mx-2 my-2" id={walkRequest._id}
                                                                    onClick={e => sendCompleteWalkAndValorationToBackend(e.target.id, walkRequest.walker._id)}>Confirmar</button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className="mb-0">
                                                                ¿El paseo fue completado?
                                                            </p>
                                                            <div className="buttons d-inline mx-3">
                                                                <i className="fa-solid fa-circle-check mx-2 my-2 accept-walk-button" id={walkRequest._id} onClick={e => setDisplayValorationInput(true)}></i>
                                                                <i className="fa-solid fa-circle-xmark mx-2 my-2 reject-walk-button" id={walkRequest._id} onClick={e => petOwnerConfirmWalkRequestCompleted(e.target.id, false)}></i>
                                                            </div>
                                                            <p className="mb-0">Pendiente por confirmar desde {formatToFullDate(walkRequest.updatedAt)}</p>
                                                        </>
                                                    )
                                                }
                                            </>
                                        </Alert>
                                    </SwiperSlide>
                                ))}
                            </Stack>
                        </Swiper>
                    </>
                ))}
        </div>
    )
}

const completeWalkPopover = (
    <Popover id="completeWalkPopover-basic">
        <Popover.Body>
            En la lista podras ver tus paseos finalizados y confirmados por el cliente, estos seran cancelados cada fin de <strong>Fin de mes</strong>
        </Popover.Body>
    </Popover>
);

const clientPendingConfirmWalkPopover = (
    <Popover id="clientPendingConfirmWalkPopover-basic">
        <Popover.Body>
            El paseador ha confirmado que el paseo se realizo exitosamente, por favor confirma esta información para añadirlo a tu lista de <strong>paseos completos.</strong>
        </Popover.Body>
    </Popover>
);

const acceptedWalksPopover = (
    <Popover id="acceptedWalksPopover-basic">
        <Popover.Body>
            En esta lista podras encontras los paseos que has aceptado en tu programación.
        </Popover.Body>
    </Popover>
);

const walkerPendingConfirmWalkPopover = (
    <Popover id="walkerPendingConfirmWalkPopover-basic">
        <Popover.Body>
            Se ha enviado una solicitud al cliente para que pueda confirmar si el paseo se completo de manera exitosa, luego de esto se añadira a tu lista de <strong>paseos completos.</strong>
        </Popover.Body>
    </Popover>
);

export default WalkRequests