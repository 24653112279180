import { useState } from "react";
import API from "../../utils/API";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Material UI imports
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const WalkRequestSection = ({ userInfo }) => {
    const authenticated = useSelector((state) => state.user.authenticated);

    const [requestingWalk, setRequestingWalk] = useState(false)
    const [date, setDate] = useState();
    const [hour, setHour] = useState();
    const [minute, setMinute] = useState();
    const [turn, setTurn] = useState();
    const [termsAccepted, setTermsAccepted] = useState(false);

    const navigate = useNavigate();

    const handleChangeHour = (event) => {
        setHour(event.target.value);
    };

    const handleChangeMinute = (event) => {
        setMinute(event.target.value);
    };

    const handleChangeTurn = (event) => {
        setTurn(event.target.value);
    };

    const handleChangeDate = (event) => {
        setDate(event.target.value);
    };

    const fixHourByTurn = (hour, turn) => {
        return turn.toLowerCase() === "pm" ? `${hour + 12}:00` : `${hour}:00`
    }

    const turnStringIntoDateType = (hour, date) => {
        const dateString = `${date} ${hour}`;
        const dateObj = new Date(dateString);
        return dateObj
    }

    const generateWalkRequest = (event, walkerSelected) => {
        event.preventDefault();

        if (hour === undefined) {
            toast.error("Debes ingresar una hora de paseo");
            return;
        }

        if (minute === undefined) {
            toast.error("Debes ingresar el minuto del paseo");
            return;
        }

        if (turn === undefined) {
            toast.error("Debes ingresar el turno del paseo");
            return;
        }

        if (date === undefined) {
            toast.error("Debes ingresar el día del paseo");
            return;
        }

        setRequestingWalk(true);

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const walkerId = walkerSelected._id;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-user-id": userId,
                "x-access-token": token,
            },
        };

        const fixHour = fixHourByTurn(hour, turn)

        const schedule = turnStringIntoDateType(fixHour, date)

        const body = {
            schedule: schedule,
            petOwnerId: userId,
            walkerId: walkerId,
        };

        API.post("/api/walk-request", body, config)
            .then((res) => {
                setDate();
                setHour();
                setMinute();
                setTurn();
                toast.success("Creaste la solicitud de paseo exitosamente");
                setRequestingWalk(false);
            })
            .catch((error) => {
                toast.error(error.response.data);
                setRequestingWalk(false);
                console.log(error);
            });
    };

    return (
        <div className='bg-white p-3 w-100  shadow border' style={{ borderRadius: '20px' }}>
            {
                authenticated ? (
                    <>
                        <h5 className="text-center mb-3">Solicitud de paseo</h5>
                        <Box sx={{ minWidth: 120, marginBottom: 2, textAlign: "center" }}>
                            <FormControl>
                                <label htmlFor="date" className="mb-1">Fecha de paseo</label>
                                <input className="rounded p-2" type="date" id="date" onChange={handleChangeDate} />
                            </FormControl>
                        </Box>
                        <Box sx={{ minWidth: 120, marginBottom: 2, textAlign: "center" }}>
                            <FormControl className="mx-1" style={{ width: "30%" }}>
                                <InputLabel id="hour-label">Hora</InputLabel>
                                <Select labelId="hour-label" id="hour-label" value={hour} label="hour" onChange={handleChangeHour}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={11}>11</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="mx-1" style={{ width: "30%" }}>
                                <InputLabel id="minute-label">Minuto</InputLabel>
                                <Select labelId="minute-label" id="minute-label" value={minute} label="hour" onChange={handleChangeMinute}>
                                    <MenuItem value={5}>05</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={35}>35</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={45}>45</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={55}>55</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="mx-1" style={{ width: "30%" }}>
                                <InputLabel id="turn-label">Turno</InputLabel>
                                <Select labelId="turn-label" id="turn-label" value={turn} label="turn" onChange={handleChangeTurn}>
                                    <MenuItem value={"am"}>AM</MenuItem>
                                    <MenuItem value={"pm"}>PM</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <div className="text-center mb-3">
                            <label htmlFor="terminos-y-condiciones">Acepto los <span style={{ color: 'blue', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate("/terminos")}>terminos y condiciones</span></label>
                            <input type="checkbox" id="terminos-y-condiciones" className="ms-1" onChange={(event) => setTermsAccepted(event.target.checked)} />
                        </div>
                        <div className="d-flex justify-content-evenly">
                            {requestingWalk ? (
                                <button className="btn btn-success" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="ms-2">Cargando...</span>
                                </button>
                            ) : (
                                <button type="button" className={termsAccepted ? "btn btn-success" : "disabled btn btn-success"} style={{ width: "45%" }} onClick={(event) => generateWalkRequest(event, userInfo)}>
                                    Generar Solicitud
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    <h5 className="text-center mb-0">Debes iniciar sesión para solicitar paseos</h5>
                )
            }

        </div>
    )
}

export default WalkRequestSection