import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from "react-router-dom";
import RatingAndViews from '../shared/RatingAndViews';
import './WalkerCard.css'
import { limitarString } from '../../utils/limitarString';

const WalkerCard = ({ walker }) => {
    const navigate = useNavigate();

    return (
        <div className='card p-2 my-2 bg-white product-card shadow border product-card-marketplace' onClick={() => navigate("/perfil/" + walker._id)} >
            <div className='card-body p-0' style={{ overflow: 'hidden' }}>
                <img className="walker-card-image" src={walker.image.url} alt="" />
                <div>
                    <h6 className='mb-0 mt-2' style={{ fontWeight: 'bold' }}>{walker.fullname}</h6>
                    <>
                        <LocationOnIcon style={{ color: 'grey', marginLeft: '-5px' }} />
                        <p className="d-inline" style={{ textTransform: 'capitalize' }}>
                            {limitarString(walker.address ? walker.address : "Sin ubicación")}
                        </p>
                    </>
                    <RatingAndViews ratingList={walker.walkerRating} viewCount={walker.viewCount} />
                    <p className='text-primary mb-0'>
                        <b>
                            {limitarString(`$ ${walker.walkerPrice ? walker.walkerPrice.toLocaleString('en-US').replace(',', '.') : '5.000'} / Hora`)}
                        </b>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WalkerCard