import * as React from 'react';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import API from "../../utils/API";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { countWalkRequests } from "../../features/user/userSlice";
import Popover from '@mui/material/Popover';
import NotificationContain from './NotificationContain';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const Notifications = () => {
    const authenticated = useSelector((state) => state.user.authenticated);
    const [allRequests, setAllRequests] = useState([])
    const [totalNotifications, setTotalNotifications] = useState(0);

    const dispatch = useDispatch();
    dispatch(countWalkRequests(totalNotifications));

    useEffect(() => {
        getTotalNotifications();
    }, [])

    function getTotalNotifications() {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        authenticated && (
            // Get walk requests
            API.get(`/api/walk-request`, { headers })
                .then((res) => {
                    const response = res.data;
                    setAllRequests(response);
                    const pendingRequests = response.filter(request => request.status === 'pendiente' || request.status === 'por-confirmar');
                    setTotalNotifications(pendingRequests.length)
                })
                .catch((err) => {
                    console.log(err);
                    setTotalNotifications(0)
                })
        )
    }

    // Codigo de Popover
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // Final de codigo Popover

    return (
        <div>
            <IconButton
                aria-describedby={id}
                className='me-4 p-0 my-auto'
                size="large"
                aria-label="Show notifications"
                color="inherit"
                onClick={handleClick}
            >
                <Badge badgeContent={totalNotifications ? totalNotifications : 0} color="primary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {totalNotifications > 0 ? (
                        allRequests.map((notification) => (
                            <NotificationContain key={notification._id} notification={notification} />
                        ))
                    ) : (
                        <ListItem alignItems="flex-start" style={{ cursor: 'pointer' }}>
                            <ListItemText
                                primary={`No tienes notificaciones`}
                            />
                        </ListItem>
                    )
                    }
                </List>
            </Popover>
        </div>
    )
}

export default Notifications