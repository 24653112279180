import { useSelector, useDispatch } from "react-redux";
import Logo from '../shared/Logo';
import { useNavigate } from "react-router-dom";
import { setUserImage, setAuthenticated } from "../../features/user/userSlice";
import './Navbar.css';
import toast from "react-hot-toast";
import { useState } from "react";

// Material UI import
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Hidden } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Notifications from './NotificationsBell';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PetsIcon from '@mui/icons-material/Pets'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MedicationIcon from '@mui/icons-material/Medication';
import FoodBankIcon from '@mui/icons-material/FoodBank';

const pages = ['Productos', 'Servicios', 'Paseadores', 'Negocios', 'Adopta', 'Fundaciones'];
const settings = ['Perfil', 'Cerrar Sesión'];
const settingsUnauthenticateUser = ['Iniciar Sesión', 'Registarse'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [activePage, setActivePage] = useState(''); // Initialize with an empty string or the default active page

    const authenticated = useSelector((state) => state.user.authenticated);
    const profilePhoto = useSelector((state) => state.user.userImage);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const onClickMenuElement = (page) => {
        setActivePage(page);
        handleCloseNavMenu();
        const pageLowerCase = page.toLowerCase();

        let route = '/marketplace'; // Ruta por defecto (en caso de que no haya coincidencia)

        // Verificar el valor de 'page' y establecer la ruta correspondiente
        if (pageLowerCase === 'paseadores') {
            route = '/paseadores';
        } else if (pageLowerCase === 'negocios') {
            route = '/negocios';
        } else if (pageLowerCase === 'productos') {
            route = '/tienda';
        } else if (pageLowerCase === 'servicios') {
            route = '/servicios';
        } else if (pageLowerCase === 'adopta') {
            route = '/adopta';
        } else if (pageLowerCase === 'fundaciones') {
            route = '/fundaciones';
        }

        navigate(route); // Redirigir a la ruta correspondiente
    };

    const onClickProfileMenuElement = (setting) => {
        handleCloseNavMenu();
        const settingLowerCase = setting.toLowerCase();

        // Verificar el valor de 'page' y establecer la ruta correspondiente
        if (settingLowerCase === 'perfil') {
            navigate('/perfil')
        } else if (settingLowerCase === 'cerrar sesión') {
            logOut();
        } else if (settingLowerCase === 'iniciar sesión') {
            navigate('/login')
        } else if (settingLowerCase === 'registarse') {
            navigate('/registro')
        }
    };

    const logOut = () => {
        try {
            localStorage.clear();
            navigate("/login");
            toast.success("Cerraste sesión exitosamente");
            dispatch(setAuthenticated());
            dispatch(setUserImage());
        } catch (error) {
            toast.error("Hubo un error al cerrar sesión");
        }
    };

    const getIconForPage = (page) => {
        switch (page.toLowerCase()) {
            case 'productos':
                return <ShoppingCartIcon className="me-1" />;
            case 'servicios':
                return <MedicationIcon className="me-1" />;
            case 'paseadores':
                return <DirectionsWalkIcon className="me-1" />;
            case 'negocios':
                return <BusinessCenterIcon className="me-1" />;
            case 'adopta':
                return <PetsIcon className="me-1" />;
            case 'fundaciones':
                return <FoodBankIcon className="me-1" />;
            default:
                return null;
        }
    };


    return (
        <AppBar style={{ position: 'sticky', top: 0, zIndex: 999, backgroundColor: '#ff870a' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Hidden mdDown>
                        <Logo />
                    </Hidden>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => navigate("/home")}
                        className='navbar-petgout-title'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Petgout
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => onClickMenuElement(page)}>
                                    <Typography
                                        textAlign="center"
                                    >
                                        <b className="d-flex align-items-center">{getIconForPage(page)}{page}</b>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Hidden mdUp>
                        <Logo />
                    </Hidden>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        onClick={() => navigate("/home")}
                        className='navbar-petgout-title'
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Petgout
                    </Typography>
                    <Box className='justify-content-center' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => onClickMenuElement(page)}
                                sx={{
                                    my: 2,
                                    color: 'white',
                                    display: 'block',
                                }}
                            >
                                <b className={page === activePage ? "navbar-element-active" : ''}>{page}</b>
                            </Button>
                        ))}
                    </Box>
                    {
                        authenticated ? (
                            <Box className='d-flex align-items-center' sx={{ flexGrow: 0 }}>
                                <Notifications />
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar className="bg-white" alt="Remy Sharp" src={profilePhoto ? profilePhoto : "/static/images/avatar/2.jpg"} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => onClickProfileMenuElement(setting)}>
                                            <Typography textAlign="center" style={{ fontWeight: 'bold' }}>
                                                {setting.toLocaleLowerCase() === 'perfil' && <PersonIcon />}
                                                {setting.toLocaleLowerCase() === 'cerrar sesión' && <LogoutIcon />}
                                                <span className='ms-1'>{setting}</span>
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        ) : (
                            <Box className='d-flex align-items-center' sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <LoginIcon style={{ color: 'white' }} />
                                        <Typography style={{ color: 'white', marginLeft: '5px' }} textAlign="center"><b>Ingresar</b></Typography>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settingsUnauthenticateUser.map((setting) => (
                                        <MenuItem key={setting} onClick={() => onClickProfileMenuElement(setting)}>
                                            <Typography textAlign="center"><b>{setting}</b></Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        )
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
