import React from 'react'
import './ServiceCard.css'
import RatingAndViews from '../shared/RatingAndViews'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { limitarString } from '../../utils/limitarString';

const ServiceCard = ({ service, isForPublicProfile, onClick }) => {

    return (
        <div className={`card p-2 my-2 bg-white service-card border shadow ${isForPublicProfile ? '' : 'service-card-marketplace'}`} onClick={onClick} >
            <div className='card-body p-0'
                style={{
                    textAlign: 'left',
                    overflow: 'hidden'
                }}>
                <img className={`mb-2 ${isForPublicProfile ? 'service-image-for-public-profile' : ''}`}
                    src={service.image.url}
                    alt={service.image.url} />
                <div>
                    <h6 className='mb-0 no-wrap' style={{ fontWeight: 'bold' }}>
                        {limitarString(service.title)}
                    </h6>
                    {
                        !isForPublicProfile && (
                            <>
                                <LocationOnIcon style={{ color: 'grey', marginLeft: '-5px' }} />
                                <p className="d-inline  ">
                                    {limitarString(service.owner.address ? service.owner.address : "Sin ubicación")}
                                </p>
                            </>)
                    }
                    <RatingAndViews ratingList={service.valuation} viewCount={service.viewCount} />
                    <p className='text-primary mb-0'><b>${service.price.toLocaleString('en-US').replace(',', '.')}</b></p>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard