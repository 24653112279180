import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import API from "../../utils/API";

const UpdateUserAddressModal = (props) => {
    const [newUserAddress, setNewUserAddress] = useState();
    const [userId, setUserId] = useState();
    const [locations, setLocations] = useState([])

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const headers = {
            "x-access-token": token,
            "x-user-id": userId,
        };

        API.get(`/api/location`, { headers }).then((res) => {
            setLocations(res.data);
        });

        setUserId(userId);
    }, []);

    const updateAddress = async (event) => {
        try {
            event.preventDefault();

            const token = localStorage.getItem("token");
            const formData = new FormData();

            formData.append("address", newUserAddress);

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "x-user-id": userId,
                    "x-access-token": token,
                },
            };

            API.put(`/api/user/address/${userId}`, formData, config)
                .then((res) => {
                    localStorage.setItem("userAddress", newUserAddress);
                    props.setAddress(newUserAddress);
                    toast.success("Actualizaste tu ubicación");
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.response.data.message);
                });
            props.onHide();
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Elije tu nueva ubicación
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select
                    aria-label="Default select example"
                    onChange={(event) => setNewUserAddress(event.target.value)}
                >
                    <option>Selecciona un barrio</option>
                    {locations.map((location) => (
                        <option key={location._id} value={location.name}>{location.name.charAt(0).toUpperCase() + location.name.slice(1)}</option>
                    ))}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={props.onHide}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={updateAddress}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateUserAddressModal;
