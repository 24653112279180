import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import API from "../utils/API";
import WhatsAppButton from '../components/shared/WhatsAppButton'
import './Adoptions.css'

// Material UI icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

const Adoptions = () => {
  const authenticated = useSelector((state) => state.user.authenticated);

  // States required to create pet form
  const [showCreatePetForm, setShowCreatePetForm] = useState(0)
  const [name, setName] = useState("")
  const [breed, setBreed] = useState("")
  const [age, setAge] = useState(null)
  const [gender, setGender] = useState("")
  const [timeSize, setTimeSize] = useState("")
  const [image, setImage] = useState()
  const [description, setDescription] = useState("")

  const [pets, setPets] = useState([]);
  const [uploadingPet, setUploadingPet] = useState(false);

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const headers = {
    "content-type": "multipart/form-data",
    "x-access-token": token,
    "x-user-id": userId,
  };

  useEffect(() => {
    getPets();
  }, [])


  const createNewPet = async (event) => {
    event.preventDefault();
    setUploadingPet(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("breed", breed);
    formData.append("age", `${age} ${timeSize}`);
    formData.append("image", image);
    formData.append("gender", gender);

    if(description.length > 0){
      formData.append("description", description);
    }

    const config = {
      headers
    };

    API.post("/api/pets", formData, config)
      .then((res) => {
        toast.success("Has publicado una mascota");
        setUploadingPet(false);
        setShowCreatePetForm(0);
      })
      .catch((error) => {
        console.log(error);
        setUploadingPet(false);
        toast.error(error.response.data.message);
      });
  };

  const handleCreateFormFirstStep = (event) => {
    event.preventDefault()
    setShowCreatePetForm(2);
  }

  const handleDescriptionFormSubmit = () => {
    setShowCreatePetForm(3);
  }

  const getPets = async () => {
    try {
      const response = await API.get(`/api/pets/`, { headers });
      setPets(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '75vh', overflowX: 'hidden' }} className='my-4'>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="adoptions-swiper"
      >
        {
          authenticated && (
            <SwiperSlide className='adoptions-slide w-100'>
              <div style={{ backgroundColor: '#f2f2f2' }} className='h-100 w-100 d-flex flex-wrap justify-content-center align-items-center'>
                {
                  showCreatePetForm === 1 ? (
                    <CreatePetForm
                      setShowCreatePetForm={setShowCreatePetForm}
                      setName={setName}
                      name={name}
                      setBreed={setBreed}
                      breed={breed}
                      setAge={setAge}
                      age={age}
                      setGender={setGender}
                      gender={gender}
                      handleCreateFormFirstStep={handleCreateFormFirstStep}
                      timeSize={timeSize}
                      setTimeSize={setTimeSize}
                    />
                  ) : showCreatePetForm === 2 ? (
                    <PetDescriptionForm 
                      handleDescriptionFormSubmit={handleDescriptionFormSubmit}
                      setShowCreatePetForm={setShowCreatePetForm}
                      setDescription={setDescription}
                    />
                  ) : showCreatePetForm === 3 ? (
                    <UploadPetImage
                      setShowCreatePetForm={setShowCreatePetForm}
                      setImage={setImage}
                      createNewPet={createNewPet}
                    />
                  ) : (
                    <FirstPage setShowCreatePetForm={setShowCreatePetForm} />
                  )
                }
              </div>
            </SwiperSlide>
          )
        }
        {
          pets.map((pet) => (
            <SwiperSlide className='adoptions-slide w-100' key={pet._id}>
              <div
                className='image-with-shadow'
                style={{
                  backgroundImage: `url("${pet.image.url}")`,
                }}
              >
                <div className="adoption-pet-content">
                  <h2 className='mb-1' style={{ textAlign: 'left', fontWeight: 'bold' }}>{pet.name}</h2>
                  <div className='mb-1 d-flex justify-content-between' style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                    <h6 className='mb-0'><LocationOnIcon />{pet.owner.address}</h6>
                    <h6 className='mb-0'><ChildFriendlyIcon />{pet.age}</h6>
                  </div>
                  <p className='pet-description'>
                    {
                      pet.description ? (
                        <p>{pet.description}</p>
                      ) : (
                        <p>No cuenta con descripción.</p>
                      )
                    }
                  </p>
                </div>
                <div style={{ position: 'absolute', height: '45px', width: '45px', top: '18px', right: '20px' }} >
                  <WhatsAppButton typeOfMessage={4} phoneNumber={pet.owner.cellphone} name={pet.owner.fullname} />
                </div>
                {
                  pet.gender === "female" ? (
                    <FemaleIcon className='gender-icon-style' style={{ color: '#FF1493' }} />
                  ) : (
                    <MaleIcon className='gender-icon-style' style={{ color: 'blue' }} />
                  )
                }
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}

function CreatePetForm({ setShowCreatePetForm, setName, setBreed, setAge, handleCreateFormFirstStep, name, breed, age, gender, setGender, timeSize, setTimeSize }) {
  return (
    <form className="row g-2" style={{ width: '80%' }} onSubmit={(event) => handleCreateFormFirstStep(event)}>
      <div className="col-md-12">
        <label htmlFor="service-title" style={{ color: 'black' }}  >
          Nombre
        </label>
        <input
          type="text"
          className="form-control"
          style={{ borderRadius: '20px' }}
          id="service-title"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
          required
        />
      </div>
      <div className="col-md-12">
        <label htmlFor="service-title" style={{ color: 'black' }}  >
          Raza
        </label>
        <input
          type="text"
          className="form-control"
          style={{ borderRadius: '20px' }}
          id="service-title"
          value={breed}
          onChange={(event) => {
            setBreed(event.target.value);
          }}
          required
        />
      </div>
      <div className="col-md-12 d-flex">
        <div className='w-50'>
          <label htmlFor="service-title" style={{ color: 'black' }}  >
            Edad
          </label>
          <input
            type="number"
            className="form-control"
            style={{ borderRadius: '20px' }}
            id="service-title"
            value={age}
            onChange={(event) => {
              setAge(event.target.value);
            }}
            required
          />
        </div>
        <div className='w-50'>
          <label htmlFor="service-title" style={{ color: 'black' }}>
            Medida
          </label>
          <select
            className="form-control"
            style={{ borderRadius: '20px' }}
            id="service-title"
            value={timeSize}
            onChange={(event) => {
              setTimeSize(event.target.value);
            }}
            required
          >
            <option value="">Selecciona una opción</option>
            <option value="meses">Meses</option>
            <option value="años">Años</option>
          </select>
        </div>
      </div>
      <div className="col-md-12">
        <label htmlFor="service-title" style={{ color: 'black' }}>
          Genero
        </label>
        <select
          className="form-control"
          style={{ borderRadius: '20px' }}
          id="service-title"
          value={gender}
          onChange={(event) => {
            setGender(event.target.value);
          }}
          required
        >
          <option value="">Selecciona una opción</option>
          <option value="female">Hembra</option>
          <option value="male">Macho</option>
        </select>
      </div>

      <div className='mt-5 d-flex' style={{ justifyContent: 'space-evenly' }}>
        <button className='btn-petgout' onClick={() => setShowCreatePetForm(0)}>Cancelar</button>
        <button className='btn-petgout' type='submit'>Continuar</button>
      </div>
    </form>
  )
}

function UploadPetImage({ setShowCreatePetForm, setImage, createNewPet }) {
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  return (
    <form className="row g-2" style={{ width: '80%' }} onSubmit={createNewPet}>
      <div className="col-md-12">
        <label htmlFor="service-title" style={{ color: 'black' }}  >
          Elije una foto
        </label>
        <input
          type="file"
          accept='image/*'
          onChange={handleImageUpload}
          className="form-control"
          style={{ borderRadius: '20px' }}
          id="service-title"
          required
        />
        {
          previewImage ? (
            <img src={previewImage} alt="Preview" style={{ width: '200px', height: '200px', borderRadius: '20px' }} className='mt-4 mx-auto shadow' />
          ) : (
            <img src={'/images/petgout-logo.png'} alt="Preview" style={{ width: '200px', height: '200px', borderRadius: '20px' }} className='mt-4 mx-auto shadow' />
          )
        }
      </div>
      <div className='mt-4 d-flex' style={{ justifyContent: 'space-evenly' }}>
        <button className='btn-petgout' onClick={() => setShowCreatePetForm(2)}>Atras</button>
        <button className='btn-petgout' type='submit'>Crear</button>
      </div>
    </form>
  )
}

function FirstPage({ setShowCreatePetForm }) {
  return (
    <div style={{ backgroundColor: '#f2f2f2' }} className='image-with-shadow h-100 w-100 d-flex flex-wrap justify-content-center align-items-center'>
      <button className='btn-petgout' style={{ borderRadius: '20px', alignSelf: 'flex-end' }} onClick={() => setShowCreatePetForm(1)}>
        <AddCircleOutlineIcon style={{ color: 'black', fontSize: '4rem' }} />
        Publicar mascota
      </button>
      <p className='mb-5' style={{ borderRadius: '20px', alignSelf: 'end' }}>
        ¡Desliza y encuentra a tu proximo compañero!
      </p>
    </div>
  )
}

function PetDescriptionForm({ setShowCreatePetForm, handleDescriptionFormSubmit, setDescription }) {
  return (
    <form className='w-100'>
      <div className='h-100 w-100 d-flex flex-wrap justify-content-center align-items-center'>
        <label htmlFor="pet-description" className='w-100 mb-2' style={{ color: 'black' }}>
          Descripción de la mascota
        </label>
        <textarea
          style={{ padding: '10px', width: '80%', resize: 'none', fontSize: '16px', borderRadius: '20px', border: 'none' }}
          maxlength="200"
          placeholder='Escribe aqui.'
          id='pet-description'
          onChange={event => setDescription(event.target.value)}
        />
      </div>
      <div className='mt-5 d-flex' style={{ justifyContent: 'space-evenly' }}>
        <button className='btn-petgout' onClick={() => setShowCreatePetForm(1)}>Anterior</button>
        <button className='btn-petgout' onClick={handleDescriptionFormSubmit}>Continuar</button>
      </div>
    </form>
  )
}

export default Adoptions